export default {
  pages: {
    landing: {
      primaryText:"Tervetuloa personoitavien tuotteiden verkkokauppaan!",
      blueBoxText: "Kierrätettävä ja biohajoava, ympäristöystävällinen kartonkikuppi omalla painatuksellasi. Kokonaan kotimaista tuotantoa.",
      imageOneText: "Kierrätettävä ja biohajoava, kierrätettävä, kompostoitava",
      imageTwoText: "100% kotimaista, vastuullista tuotantoa",
      imageThreeText: "Tilausmäärät tarpeen mukaan - ei ylijäämää",
      kuppiHeading: "Yksilölliset kupit yrityksellesi",
      kuppiText: "Voit suunnitella kuppiin painettavan kuvituksen palvelussamme! Taustakuva, teksti ja värit ovat vapaasti valittavissasi. Voit lisätä myös oman logosi. 3D-esikatselulla näet miltä painettu kuppi tulee näyttämään.",
      kuppiTextTwo: "Voit käyttää myös PMpro Suunnittelupalvelua, lähetä vain aineistot meille niin suunnittelemme kuppisi.",
      kuppiButtonText: "Tästä suunnittelemaan",
      lidsHeading: "Kartonkikannet",
      lidsText: "Meiltä saat myös kuppeihin sopivat kartonkikannet, kansia löytyy 240 ml ja suuremmille kupeille. Kannet tehdään Suomessa kotimaisesta vesidispersiopäällystetystä kartongista ja niihin ei lisätä muovia.",
      lidsButtonText: "Tilaa kannet",
      tuotteetHeading: "Tuotteemme",
      tuotteetLineOne: "- Kuppeja on neljää eri kokoa",
      tuotteetLineTwo: "- Määrät tarpeen mukaan, alkaen 100 kpl",
      tuotteetLineThree: "- Toimitusaika noin 2 - 3 vkoa",
      tuotteetLineFour: "- Tuotannolla laatu-, hygienia- ja elintarvikekelpoisuussertifikaatit",
      tuotteetButtonText: "Kuppien koot näet täältä",
      tilaaText: "Tilaa uutiskirjeemme",
      pysyText: "Pysy ajan tasalla pakkausmaailman kuulumisista ja uutuuksista kaupassamme!",
      tilaaButton: "Tilaa uutiskirje",
      kuppiOmallaHeading: "Lisää pakkauksia Pyrollkaupassa",
      kuppiOmallaText: "Tulemme lisäämään muitakin asiakaspainettavia ympäristöystävällisiä kartonkipakkauksia tuotevalikoimaamme, mutta toistaiseksi niitä saa konsernimme muilta yhtiöiltä standardipainatuksin.",
      kuppiOmallaLastLineText: "Käy tutustumassa!",      
      kuppiOmallaButtonText: "Pyrollkauppa.fi",      
      otsikkoTitle: "Kartonkiastioiden uusi aika",
      otsikkoHeadingOne: "Erotu joukosta",
      otsikkoTextOne: "Yritykset! Take away -astia on uusi markkinointikanava. Älä tyydy tavalliseen, tee jotakin itsesi näköistä - tai ilahduta asiakkaitasi personoiduilla kupeilla. Kupin ei tarvitse olla pelkkä kuppi, se on osa viestintääsi. Mukaan otettavien astioiden avulla voit tehdä markkinointialoitteita, nopeasyklisiä kampanjoita, alueellisesti kohdennettuja ja räätälöityjä tempauksia sekä muita tuoreita ratkaisuja.",
      otsikkoHeadingTwo: "Ole luova",
      otsikkoTextTwo: "Painamme kuppeihisi oman kuvasi ja tekstisi, juuri niinkuin haluat. Taustalle voit valita oman valokuvasi tai taustavärin, käytettävissäsi ovat myös kuvitukset valmiista vaihtoehdoistamme. Tekstin voit asemoida vapaasti samoin kuin logosi tai muun pikkukuvan.",
      otsikkoHeadingThree: "Ympäristö ja vastuullisuus",
      otsikkoTextThree: "Kuppimme ovat ympäristöystävällisiä, ne tehdään vesidispersiopäällystetystä kartongista ja ne voi kierrättää kartonkijätteenä. Kuppeihin käytetty kartonki valmistetaan Suomessa ja kuppien valmistus on kokonaan suomalaista työtä. Tuotannolla on laatu-, hygienia- ja elintarvikekelpoisuussertifikaatit.",
      italic1: "Olipa nopea toimituskin, kupit saapuivat juuri meille. Hyvältä näyttävät, kiitos! 😊",
      italic1_1: "Lue lisää asiakaspalautteita",
      italic2: "Katso videosta miten luot lisäarvoa tapahtumaan kupeilla.",
      italic2_1: "Kurkista tehtaaseemme.",
      italic2_2: "Katso video",
      italic3: "Vinkkejä kuppien suunnitteluun voit lukea täältä",
      italic3_1: "Suunnitteluvinkit",
      emailErrorText: "Tarkasta sähköpostiosoite",
    },
    shopListPage: {
      headingText: "1/4 Valitse tuote",
      description: "Aloita valitsemalla kuppi. Voit lisätä samaan tilaukseen useampia tuotteita yläpalkin valikosta.",
      buttonText: "Valitse",
      lidsButtonText: "Lisää ostoskoriin",
      hinnatText: "Hinnat alkaen",
      lidsHeading: "Kannet",
      lidsAdditionalText: "kuppitilauksen yhteydessä"
    },
    editorPage: {
      title: "3/4 Kupin suunnittelu",
      subtitle: "Käytä suunnittelun tukena 3D-esikatselua. Siitä näet kupin sellaisena, kuin painettu valmis lopputulos tulee olemaan. Tarkastamme kaikki aineistot ennen painamista ja tarpeen vaatiessa otamme sinuun yhteyttä.",
      goCartButtonText: "Tallenna ja siirry ostoskoriin",
      addProductButtonText: "Lisää uusi tuote",
      costPrepText: "á",
      taxText: "ALV",
    },
    cartPage: {
      title: "4/4 Ostoskori",
      deleteProductModalText: "Oletko varma, että haluat poistaa tuotteen ostoskorista?",
      voucherButtonText: "Aktivoi",
      goProductListButtonText: "Lisää uusi tuote",
      costPrepText: "á",
      eachPieceText: "kpl",
      radioTextOne: "HENKILÖASIAKAS",
      radioTextTwo: "YRITYS / YHDISTYS",
      contactHeadingOne: "Tilaajan tiedot",
      checkboxBillText: "Toimitusosoite on eri kuin yhteystieto",
      contactRequiredFieldText: "Tämä kenttä on pakollinen",
      ytunnusText: "Y-tunnus on pakollinen, jos maksat laskulla",
      submitButtonText: "Kassalle",
      recvNamePlaceholder: "Tilaaja",
      salesTermsText: "Myynti- ja toimitusehdot",
      salesTermsClickText: "klikkaa tästä",
      salesTermsErrorText: "Myynti ja toimitusehdot on hyväksyttävä jatkaaksesi.",
      marketingText: "Kyllä, saatte lähettää minulle markkinointiviestejä.",
      suunniText: "Suunnittelupalvelu",
      taxText: "ALV",
    },
    paymentPage: {
      title: "Tilauksen vahvistaminen",
      subtitle: "Tilauksen sisältö",
      eachPrepText: "á",
      eachPieceText: "kpl",
      contactHeadingOne: "Toimitusosoite",
      contactHeadingTwo: "Laskutusosoite",
      contactHeadingThree: "Asiakastiedot",
      goBackButtonText: "Takaisin",
      payByCardButtonText: "Maksa luottokortilla",
      payByBillButtonText: "Vahvista tilaus",
      suunniText: "Suunnittelupalvelu",
      lahetammeText_1: "Lähetämme ensisijaisesti sähköisen laskun Y-tunnuksen mukaiseen sähköiseen laskutusosoitteeseen.",
      lahetammeText_2: "Mikäli sinulla ei ole sähköistä laskutusosoitetta lähetämme laskun tilaajan sähköpostiin. Emme lähetä paperilaskuja.",
    },
    thanksPage: {
      lineOne: "Kiitos tilauksestasi! Olemme vastaanottaneet maksun.",
      lineTwo: "Kiitos tilauksestasi!",
      lineThree: "Maksu peruutettu",
      lineFour: "Tilauksesi on vastaanotettu ja se odottaa vahvistustamme.",
      lineFifth: "Tilauksen yhteenveto on toimitettu osoitteeseen",
      lineSixth: "Tarkistathan myös roskaposti-kansiosi, jos tilausvahvistus ei näy saapuneissa sähköposteissa.",
      goHomepageButtonText: "Takaisin etusivulle"
    },
    termsPage: {
      title: "Toimitusehdot",
      h1: "Yleistä verkkokaupasta",
      p1_1: "Pyroll Kuitupakkaukset Oy, PackageMedia (Y-tunnus: 0159902-7) myy PMPro -verkkokaupasta tuotteita vain yrityksille. Asiakas sitoutuu jokaisen tilauksen teon yhteydessä voimassa oleviin toimitusehtoihin. Hyväksymällä tilaus- ja toimitusehdot asiakas hyväksyy, että asiakkaalle voidaan tarvittaessa lähettää sähköpostia tai soittaa tilaukseen liittyvissä asioissa.",
      p1_2_1: "Kaikki tilaukset vahvistetaan sähköpostiviestillä, Tilausvahvistuksen saaminen edellyttää sähköpostiosoitteen ilmoittamista tilauksen yhteydessä. Ota yhteyttä asiakaspalveluun,",
      p1_2_link: " pmpro{'@'}packagemedia.fi",
      p1_2_2: " mikäli et saa tilausvahvistusta lyhyen ajan kuluessa tilauksesi tekemisestä. Asiakkaan tulee tarkistaa tilausvahvistuksen oikeellisuus, sillä tuotteet toimitetaan tilausvahvistuksen mukaisesti. Tilausvahvistusviesti kannattaa säilyttää mahdollista myöhempää tarvetta varten. Kaikki asiakastiedot käsitellään luottamuksellisesti.",
      h2: "Arvonlisävero",
      p2_1: "Verkkokaupan tuotteisiin lisätään arvonlisävero 25.5%",
      h3:"Toimitustavat",
      p3_1: "Toimitamme tuotteet yleensä Schenkerin rahtina, mutta voimme käyttää myös muita toimitustapoja. Toimituskulut sisältyvät tuotteen hintaan.",
      h4:"Maksutavat",
      p4_1: "PMPro-verkkokaupan maksutapana on lasku. Maksuehto on 14 päivää netto. Viivästyskorko on 7 % + Suomen Pankin viitekorko. Yritysten on ilmoitettava Y-tunnus laskutusta varten. Palveluntarjoajalla on oikeus tarkistaa käyttäjän luottotiedot ennen tilauksen toimittamista. Kaikki tiedot käsitellään luottamuksellisesti.",
      h5:"Toimitusajat",
      p5_1: "Verkkokaupan yksilöllisesti painetut tilaukset toimitetaan 2 – 3 viikon kuluessa tilauksen saapumisesta. Varastotuotteet toimitetaan 2 – 3 päivän kuluessa tilauksesta. Mikäli tuote on hetkellisesti loppu varastostamme, otamme tilaajaan yhteyttä uuden toimitusajan sopimiseksi. Toimitusajan muuttuessa asiakkaalla on oikeus peruuttaa tilaus.",
      p5_2: "Pidätämme oikeuden kaupan perumiseen, mikäli tuote on kokonaan tai pidemmän aikaa loppuunmyyty, hinta on ilmoitettu ilmeisen virheellisesti tai jonkun muun ylivoimaisen esteen johdosta. Tällaisissa tapauksissa järjestelmä lähettää asiakkaalle tilausvahvistuksen, vaikka tuotetta ei olekaan saatavilla. PackageMedian asiakaspalvelu ottaa asiakkaaseen yhteyttä mahdollisimman nopeasti antaen asiakkaalle mahdollisuuden peruuttaa tilaus tai tarjoten korvaavaa tuotetta, mikäli sellainen on saatavilla. PackageMedia ei vastaa käyttämiensä kuljetusyritysten taikka kolmannen osapuolen kuljetuksiin liittyvistä tai muista viiveistä eikä asiakkaalle mahdollisesti aiheutuvista välillisistä vahingoista.",
      h6:"Reklamaatiot ja muut ongelmatilanteet",
      p6_1: "Mahdollisissa reklamaatioasioissa tai muissa ongelmatilanteissa pyydämme ottamaan viipymättä yhteyttä pmpro@packagemedia.fi tai puhelimitse 050 5320575. Asiakaspalvelumme on avoinna arkisin kello 8-16. Ilmoitus virheellisestä toimituksesta tai viallisesta tuotteesta on tehtävä 14 päivän sisällä toimituksen vastaanottamisesta.",
      h8:"Muuta",
      p8_1: "Pidätämme oikeuden muuttaa toimitusehtojamme. Asiakkaan on ennen tilaustaan tutustuttava kulloinkin voimassa oleviin toimitusehtoihin."
    },
    faqPage: {
      title: "FAQ – Usein kysytyt kysymykset",
      headingOne: "Miten käytetyt kupit voi kierrättää?",
      textOne: "Kupit kierrätetään kartonkijätteen joukossa",
      headingTwo: "Soveltuvatko kupit sekä kylmille että kuumille juomille?",
      textTwo: "Kyllä soveltuvat.",
      headingThree: "Kestävätkö kupit alkoholijuomia?",
      textThree: "Kupit kestävät mietoja enintään noin 10 % vol. alkoholijuomia rajoitetun ajan.",
      headingFour: "Mikä on toimitusaika?",
      textFour: "Omilla painatuksilla olevat tuotteet toimitetaan noin 10–15 arkipäivässä.",
      headingFive: "Missä tuotteet valmistetaan?",
      textFive: "Kupit ja niiden raaka-aineet valmistetaan kokonaan Suomessa.",
      headingSix: "En osaa suunnitella kuppia, voitteko auttaa?",
      textSix: "Kyllä, ota yhteyttä asiakaspalveluumme niin ammattilaisemme suunnittelevat sinulle mieleisesi kupin.",
      headingSeven: "Ovatko kupit turvallisia käyttää?",
      textSeven: "Kyllä, kuppien kaikki materiaalit ovat elintarvikekelpoisia ja kuppien valmistuksessa noudatetaan tiukkoja hygieniaohjeita. Kuppeja valmistavalla tehtaalla on ISO 22000 elintarviketurvallisuussertifikaatti sekä auditoidut laatujärjestelmät.",
      headingEight: "Onko kupeissa muovia?",
      textEight: "Kuppikartonkiin ei lisätä erillistä muovikerrosta, kuppien nesteenpitävyys saadaan aikaan vesidispersiopinnoitteella.",
      headingNine: "Pitääkö kuppeihin painaa kilpikonnalogo",
      textNine: "Vaikka kupeissa ei olekaan lisättyä muovikerrosta voi vesidispersiopäällyste sisältää muoviksi luettavia polymeerejä ja EU:n viimeisimmän 7.9.2022 tekemän linjauksen mukaan niihin on painettava kilpikonnalogo. ",
      headingTen: "Mikä on kuppien rahtikulu",
      textTen: "Kupit toimitetaan rahtivapaasti Suomessa, eli nettikaupan hinta on lopullinen maksamasi hinta.",
      headingEleven: "Miten kaupassa voi maksaa?",
      textEleven: "Yksityishenkilöt voivat maksaa pankki- tai luottokortilla. Yritykset voivat halutessaan valita maksutavaksi myös laskun, jolloin Y-tunnus on pakollinen tieto. Laskutusasiakkailta voimme tarkastaa luottotiedot.\nYrityksille lähetämme ensisijaisesti sähköisen laskun Y-tunnuksen mukaiseen sähköiseen laskutusosoitteeseen, jos sitä ei löydy lähetämme laskun PDF-muodossa tilaajan sähköpostiin. Emme lähetä paperilaskuja.",
      headingTwelve: "Mistä saan kilpikonnalogon painatusaineistoon?",
      textTwelve: "Sinun ei tarvitse huolehtia siitä, 3D-suunnitteluohjelmamme lisää sen automaattisesti oikean kokoisena kupin alareunaan, se näkyy esikatselussa ja suunnittelupohjassa.",
      headingThirteen: "Miksi PMPron kupit ovat normaalia helpommin kierrätettäviä ja vastuullisempi valinta?",
      textThirteen: "Kaikki kartonkikupit tarvitsevat jonkinlaisen pinnoitteen, jolla varmistetaan kuppien valmistuksessa niiden saumautuvuus ja toisaalta nesteenpitävyys. Vaihtoehtoja pinnoitteeksi ovat muovi (PE =polyeteeni), biomuovi (PLA = poly lactic acid), joka on yleensä sokeriruokopohjainen muovi. Meidän käyttämämme vesipohjainen dispersio ei muodosta kuppiin erillistä muovikerrosta ja se on biohajoavaa ja kierrätettävää ja näistä vaihtoehdoista ympäristöä vähiten kuormittava. Myös valmistuksen 100% kotimaisuus on yksi vastuullisuustekijä, luonnon kuormitus vähenee kun materiaaleja ei kuljeteta pitkiä matkoja.",
      footer: "Jos jokin seikka jäi mietityttämään, ota yhteyttä",
      footerLink: "pmpro@packagemedia.fi",
    },
    pricingPage: {
      title: "Hinnasto",
      p1: "Palvelun hinta on riippuvainen valitsemastasi tuotteesta ja tilausmäärästä. Kun ostat enemmän, saat tuotteen edullisemmin. HUOM! Voit yhdistää useamman samaa kokoa olevan kupin painatuksen samaan tilaukseen, silloin hinta muodostuu kuppien kokonaismäärän mukaan. Voit siis tilata pieneenkin määrään kuppeja useamman erilaisen painatuksen ilman että se lisää hintaa. Ajantasaisen hinnaston kullekin tuotteelle näet tältä sivulta.",
      p2: "Tilauksen lopullinen hinta muodostuu kassalla.",
      eachPieceText1: "kpl",
      eachPieceText2: "à",
      suunniText: "Suunnittelupalvelu",
      lids1: "Erikseen tilattuna",
      lids2: "Kuppitilauksen yhteydessä"
    },
    aboutPage: {
      title1: "Keitä me olemme",
      p1: "PMPro on PackgageMedian verkkokauppa, joka tarjoaa yksilöllisesti painettuja kartonkikuppeja ja jatkossa myös muita take-away ja markkinointituotteita. PackageMedia on osa Pyroll Pakkaukset Groupia, joka on Suomen suurin kuluttajapakkausten valmistaja. Tuskinpa pääset kauppareissulta kotiin ilman että kädessäsi olisi joku Pyrollin tekemä pakkaus. Pyroll on 100% kotimainen toimija, jonka omistaja on niinikään suomalainen MB Rahastot.",
      p2: "PackageMedia on Pyrollin digitaalinen pakkaustehdas ja intohimomme on yhdistää tuotteisiin digitaalisia palveluja sekä tarjota yksilöityjä painatuksia pieniinkin tilauseriin, meillä se onnistuu. Tänne PMPro verkkokauppaan olemme kehittäneet ainutlaatuisen suunnittelukoneen, jonka avulla voit suunnitella kuppisi ja muut tuotteet suoraan oman laitteesi ruudulla ja katsoa liikuteltavasta 3D-kuvasta, miltä esim, kuppisi tulee näyttämään. Voit myös käyttää suunnittelupalveluamme, jos niin haluat tai ladata valmiiksi suunnitellun kuppiaineiston. Kaikki käy.",
      p3: "Tuotantomme on Kouvolan Inkeroisissa, koko tuotantoketju saman katon alla. Meillä on kaikki elintarvikepakkausten tuotannon edellyttämät sertifikaatit ja varmistamme tuotantomme turvallisuutta myös omilla testeillä jatkuvasti.",
      title2: "Mitä asiakkaamme ovat mieltä",
      italic1: "On kyllä ollut teiltä aivan erinomaista palvelua tähän saakka, lämmöllä aion suositella kollegoille.",
      italic2: "Olipa nopea toimituskin, kupit saapuivat juuri meille. Hyvältä näyttävät, kiitos! 😊",
      italic3_p1: "KooKoo on jo useamman vuoden tehnyt töitä sen eteen, että tapahtumat olisivat mahdollisimman vastuullisia ja ympäristöä vähän kuormittavia, haluamme antaa oman panoksemme ilmastotalkoisiin ja uskomme, että myös kannattajamme arvostavat sitä. Tähän liittyy yhtenä osana tapahtumissa käytettävien tarjoiluastioiden esim kuppien, mukien jne. vaihtaminen mahdollisimman vastuulliseen vaihtoehtoon.",
      italic3_p2: "Muovisten oluttuoppien korvaaminen kartonkituopeilla voi ensisilmäyksellä vaikuttaa kalliimmalta vaihtoehdolta, mutta pitkällä tähtäimellä se on investointi, joka maksaa itsensä takaisin moninkertaisesti säästämällä luontoa ja parantamalla seuran imagoa.",
      italic3_p3: "PMPron kartonkituopit ja -kupit ovat ympäristöystävällisempi vaihtoehto, ne eivät rasita luontoa samalla tavalla kuin muovituotteet ja muovipäällysteiset tuotteet. Kartonkituopit ja -kupit ovat kompostoituvia ja helposti kierrätettäviä ja niiden valmistusprosessi on yleensä vähemmän saastuttava. Mukien raaka-aineet ovat kotimaisia kartonkeja ja tuotanto on Suomessa, itse asiassa tässä Kouvolassa. Ostamalla Suomesta voimme osaltamme olla tukemassa kotimaista tuotantoa ja osoittaa siten vastuullisuutemme. On myös tärkeää, että yleisö peleissä ja tapahtumissa tietää astioiden olevan ympäristöystävällisiä ja kierrätettäviä.",
      title3: "Kupit osana tapahtumaa",
      title4: "Kontaktisi PMPro:ssa",
      mauri1: "Liiketoimintajohtaja, Pyroll Kuitupakkaukset Oy, PackageMedia",
      mauri2: 'Mauri on luotsannut yritystä noin 30 vuotta ja hänen erityisosaamistaan on digitaalisen maailman ja fyysisen pakkauksen yhdistäminen, "haluamme toimia vastuullisesti ja antaa pakkaukselle muutakin tehtävää kuin vain tuotteen suojaamisen". Mauri vastaa palvelumyynnistä ja yleisjohdosta.',
      hannu1: "Tehdaspäällikkö/Myyntipäällikkö",
      hannu2: 'Hannu on toiminut kuitupakkausten parissa koko noin 30-vuotisen työuransa. Nyt hän vastaa PackageMedian tuotannosta ja kuppien sekä muiden tuotteiden myynnistä, "yritetään toteuttaa se mitä Mauri tuolla kentällä lupaa asiakkaille".',
      tiina: "- Tiina-Riikka Turunen, Sulkavan kunta",
      heini: "- Heini",
      sakari: "- Sakari Välimaa, toimitusjohtaja, KooKoo Hockey Oy",
    },
    tipsPage: {
      title1: "Vinkkejä",
      title2: "Aineiston tekeminen valmiiksi kuvankäsittylyohjelmalla",
      p1: "Voit tehdä painatusaineiston valmiiksi tiedostoksi kuppipohjan kokoiseksi tai mielellään muutaman millin suuremmaksi jollakin kuvankäsittelyohjelmalla. Painettavan alueen koot ovat:",
      p2: "Tee ohjelmalla valmis kuva teksteineen ja mahdollisine logoineen ja tuo se taustakuvana suunnittelupohjaan, silloin se asettuu peittämään koko kupin alan.",
      p3: "Voit zoomata kuvaa tarvittaessa",
      title3: "Apuviivaston / -ruudukon käyttö",
      p4: "Suunnitteluohjelmassa on ruudukko, jota voi käyttää apuna elementtien asemoimiseen samalle linjalle tai esim. tasan vastakkaisille puolille, kuten logot kuvassa tai keskelle kuppia kuten kilpikonnalogo. Ruudukon viivat eivät näy 3D-katselussa eivätkä ne tulostu kuppiin.",
      title4: "Kilpikonnalogo",
      p5: "Kilpikonnalogo on liikuteltavissa ja sen voi sijoittaa kupissa parhaiten sopivaan paikkaan. SUP-direktiivin ohjeistuksen mukaan sen on kuitenkin oltava määrätyn kokoinen ja vaakasuorassa.",
      title5: "Muita vinkkejä suunnitteluun",
      p6: "Kuppien alaosaan jää noin 6 mm korkea valkoinen painamaton alue, se tarvitaan saumauksen keston varmistamiseksi.",
      p7: "Jos kupissa on yhtenäinen taustaväri, sauman kohdalle voi tulla 1 – 2 mm porrastus johtuen kupinmuodostuksen hyväksyttävistä toleransseista samoin voi käydä sauman yli jatkuville kuvioille. Eli kannattaa tehdä kuviot niin, että ne eivät jatku sivusauman yli tapettikuviona, tai huomioi se, että kohdistamisessa voi olla pientä heittoa.",
      p8: "Kuppien painatus on digitaalista ja kuvien painolaatu on valokuvatasoa. kuppeihin käytettävä kartonki on kuitenkin pinnaltaan hiukan kellertävää ja voi vaikuttaa kuvien laatuun. Jos käsittelet kuvia ne kannattaa tehdä hiukan normaalia voimakkaamman sävyisiksi ja muutenkin käyttää kirkkaita värejä, ne toistuvat parhaiten.",
      title6: "Painoaineiston toimittaminen, PMS-värit",
      p9: "Voit toimittaa meille painoaineiston myös valmiina tiedostona esim PDF-muodossa. Painamme kupit 7-värisellä digipainokoneella jossa on CMYK-värit + 3 lisäväriä, jolloin voimme käyttää EPG-väriavaruutta, jolla pystymme toistamaan noin 95 % kaikista PMS väreistä. Kuppien kartonki on kuitenkin hiukan kellertävää, joka voi vaikuttaa värien toistumiseen, kriittisiin väreihin kannattaa lähettää meille tarkka tieto haettavasta PMS-väristä, jolloin vertaamme sitä värikarttaan ja haemme värit kohdilleen."
    },
    designMethodPage: {
      h3_1: "2/4 Valitse toteutustapa",
      p_1: "Voit suunnitella kupin ulkoasun itse seuraavassa vaiheessa, tai lähettää aineistot meille, jolloin ammattilaisemme suunnittelevat kupin puolestasi. Suunnittelupalvelun hinta on 60,00 € + alv.",
      p_2: "Valittu tuote:",
      p_3: "Suunnittele itse: käytä tätä vaihtoehtoa, kun haluat testailla ja kokeilla erilaisia vaihtoehtoja",
      p_4: "Käytä PMpro Suunnittelupalvelua, kun luotat mieluummin ammattilaisen silmään.",
      button_1: "Suunnittelen kupin itse",
      button_2: "Suunnittelupalvelu",
    },
    requestOrderPage: {
      lahetaText: "3/4 Lähetä aineistot",
      taytaText: "Täytä alle tarvittavat tiedot. Teemme sinulle kupista koevedoksen, jonka saat sähköpostiisi 2 - 3 päivän kuluessa. Jos sinulla on valmiiksi tehty design, voit lähettää sen esim. pdf-tiedostona kohdassa Tausta. Kun aineiston koonti on valmis, siirry ostoskoriin täyttämään tilaustiedot.",
      valittuText: "Valittu tuote",
      tilausText: "Tilausmäärä",
      taustaText: "Tausta",
      sallitutText: "Sallitut tiedostomuodot .jpg, .png, .pdf",
      kuvaText: "Kuva",
      valitseText_1: "Valitse tiedosto",
      poistaText: "Poista",
      variText: "Väri",
      valitseText_2: "Valitse taustaväri",
      eitaustaaText: "Ei taustaa",
      logoText: "Logo",
      tekstiText: "Teksti",
      eitekstiaText: "Ei tekstiä",
      ohjeetText: "Ohjeet",
      jatkaText: "Jatka ostoskoriin"
    }
  },
  components: {
    addProduct: {
      modalText: "SUP-DIREKTIIVIN VAATIMUKSET\nkupeissa käyttämämme kartonki on kotimaista ekologista kuppikartonkia, johon ei lisätä erillistä\nmuovikerrosta. Kuppien nesteenpitävyys saadaan aikaan vesidispersiopinnoituksella.\nEU:n SUP-direktiivi kuitenkin määrittelee vesidispersiopinnoitteen muoviksi, koska se saattaa\nsisältää muovin kaltaisia polymeerejä ja direktiivin viimeisimmän tulkinnan mukaisesti\nkuppeihimme on painettava alla oleva kilpikonnalogo\nBiohajoavat kuppimme ovat kuitenkin ympäristölle parempi vaihtoehto kuin muovipinnoitetusta\nmateriaalista valmistetut ja niiden kierrättäminen on helppoa.\nMuistathan kierrättää kupit vastuullisesti!"
    },
    fabricComponent: {
      undoText: "Kumoa",
      redoText: "Tee uudelleen",
      clearAllText: "Tyhjennä kaikki",
      backgroundText: "Tausta",
      textText: "Teksti",
      logoText: "Kuva/Logo",
      shapesText: "Muoto",
      addNewText: "Lisää uusi",
      addCircle: "Ympyrä",
      addRectangle: "Neliö",
      colorText: "Väri",
      fontText: "Fontti",
      selectImageText: "Valitse kuva",
      removeText: "Poista",
      loitonnaText: "Loitonna/lähennä",
      vaihdaText: "Valitse/Vaihda",
      kierraText: "Kierrä",
      kohdistaText: "Kohdista",
      deleteBackgroundPicture: "Poista",
      saveText: "Tallenna",
      cancelText: "Peruuta",
      selectColor: "Valitse väri",
      cancelColor: "Peruuta",
      grid: "Ruudukko",
      lataaOma: "Lataa oma kuvasi tai valitse valmiista vaihtoehdoista",
      valitseTiedosto: "Valitse tiedosto",
      d3Esikatse: "3D Esikatselu"
    },
    preview3d: {
      kierra: "Kierrä kuvaa raahaamalla",
    },
    navbar: {
      optionTuotteet: "Tuotteet",
      optionVinkk: "Vinkkejä pakkauksen suunnitteluun",
      optionUsein: "Usein kysytyt kysymykset",
      optionTilaus: "Tilaus- ja toimitusehdot",
      optionPmpro: "PMpro ja yhteystiedot",
      optionsHinnasto: "Hinnasto",
      optionOta: "Ota yhteyttä",
    },
    footer: {
      power: "Powered by",
      tieto: "Tietosuojaseloste"
    },
    colorPickerFooter: {
      p1: "Nykyinen valinta:"
    }
  },
  modals: {
    addProductModal: {
      buttonText: "Lisää tilaukseen"
    },
    cartModal: {
      totalText: "Yhteensä:",
      resetButtonText: "Tee uusi tilaus",
      goCartButtonText: "Ostoskoriin"
    },
    contactModal: {
      headingText: "Ota yhteyttä",
    },
    cookieNotification: {
      textOne: "Evästeiden avulla parannamme käyttökokemustasi ja kehitämme palveluamme.",
      textTwo: "Käytämme sekä omia että kolmansien osapuolien evästeitä. Lue lisää käyttämistämme",
      textTwoLink: "evästeistä.",
      textThree: "Voit hyväksyä evästeet tai muuttaa evästeasetuksia, miten niiden avulla kerättyä tietoa on mahdollista käyttää käytetään.",
      declineButton: "Vain välttämättömät evästeet",
      acceptButton: "Hyväksyn kaikki evästeet",
    },
    notificationModal: {
      p1: "SUP-DIREKTIIVIN VAATIMUKSET",
      p2: "Kupeissa käyttämämme kartonki on kotimaista ekologista kuppikartonkia, johon ei lisätä erillistä muovikerrosta. Kuppien nesteenpitävyys saadaan aikaan vesidispersiopinnoituksella.",
      p3: "EU:n SUP-direktiivi kuitenkin määrittelee vesidispersiopinnoitteen muoviksi, koska se saattaa sisältää muovin kaltaisia polymeerejä ja direktiivin viimeisimmän tulkinnan mukaisesti kuppeihimme on painettava alla oleva kilpikonnalogo",
      p4: "Biohajoavat kuppimme ovat kuitenkin ympäristölle parempi vaihtoehto kuin muovipinnoitetusta materiaalista valmistetut ja niiden kierrättäminen on helppoa.",
      p5: "Muistathan kierrättää kupit vastuullisesti!",
    },
    privacyPolicyModal: {
      title: "Tietosuojaseloste",
      h1: "1. Rekisteriseloste",
      p1_1: "Henkilötietolaki (523/99) 10 §",
      p1_2: "Laatimispäivä 8.8.2022",
      h11: "1.1. Rekisterinpitäjä",
      h12: "1.2. Rekisteriasioista vastaava",
      h13: "1.3. Rekisterin nimi",
      p13_1: "PMPro verkkokaupan käyttäjärekisteri",
      h14: "1.4. Henkilötietojen käsittelyn tarkoitus (rekisterin käyttötarkoitus)",
      p14_1: "PMPro verkkokaupan käyttäjärekisteriin tallennettuja henkilötietoja käytetään asiakassuhteiden hoitamiseen, yhteydenottojen hoitamiseksi, markkinointitarkoituksiin sekä muihin verkkopalveluun liittyviin tarkoituksiin.",
      h15: "1.5. Rekisterin tietosisältö",
      p15_1: "Rekisteriin kerätään rekisteröidyistä perustietoja, kuten:",
      p15_2: "- nimi",
      p15_3: "- osoit",
      p15_4: "- puhelin",
      p15_5: "- sähköposti",
      p15_6: "- yksityishenkilöiltä henkilötunnus ja yrityksiltä Y-tunnus laskutusta varten",
      h16: "1.6. Säännönmukaiset tietolähteet",
      p16_1: "Rekisterinpitäjä rekisteröi PMPro verkkokaupan käyttäjästä ne tiedot, jotka käyttäjä itse ilmoittaa verkkosivustoa käyttäessään.",
      h17: "1.7. Säännönmukaiset tietojen luovutukset ja tietojen siirto EU:n tai Euroopan talousalueen ulkopuolelle",
      p17_1: "Ei säännönmukaisia tietojen luovutuksia kolmansille osapuolille. Ei tietojen luovutuksia EU:n tai ETA:n ulkopuolelle.",
      h18: "1.8. Rekisterin suojauksen periaatteet",
      p18_1: "PMPro verkkokaupan käyttäjärekisterin tiedot on tallennettu rekisterinpitäjän järjestelmään. Järjestelmään sisäänpääsy edellyttää käyttäjätunnuksen ja salasanan syöttämistä. Järjestelmä on myös suojattu palomuurein ja muiden teknisten keinojen avulla. Järjestelmään tallennettuihin rekisterin sisältämiin tietoihin pääsevät ja niitä ovat oikeutettuja käyttämään vain tietyt, ennalta määritellyt rekisterinpitäjän työntekijät. Rekisterin sisältämät tiedot sijaitsevat lukituissa ja vartioiduissa tiloissa.",
      h19: "1.9. Rekisteröidyn kielto-oikeus",
      p19_1: "Rekisteröidyllä on oikeus kieltää rekisterinpitäjää käsittelemästä häntä itseään koskevia tietoja suoramainontaa, etämyyntiä ja muuta suoramarkkinointia sekä markkina- ja mielipidetutkimusta samoin kuin henkilömatrikkelia ja sukututkimusta varten. Kielto tulee tehdä kirjallisesti ja osoittaa rekisteriasioista vastaavalle henkilöille.",
      h110: "1.10. Rekisteröidyn tarkastusoikeus",
      p110_1: "Rekisteröidyllä on oikeus tarkastaa rekisteriin tallennetut itseään koskevat tiedot ja saada niistä kopiot. Tarkastuspyyntö tulee tehdä kirjallisesti ja osoittaa rekisteriasioista vastaavalle henkilölle.",
      h111: "1.11. Tiedon korjaaminen",
      p111_1: "Rekisterinpitäjä oikaisee, poistaa tai täydentää rekisterissä olevan, käsittelyn tarkoituksen kannalta virheellisen, tarpeettoman, puutteellisen tai vanhentuneen henkilötiedon oma-aloitteisesti tai rekisteröidyn vaatimuksesta. Rekisteröidyn tulee ottaa yhteyttä rekisterinpitäjän rekisteriasioista vastaavaan henkilöön tiedon korjaamiseksi.",
      h2: "2. Evästeiden käyttö",
      p2_1: "PMPro verkkokauppa käyttää evästeitä käyttäjäkokemuksen parantamiseksi.",
      p2_2: "Eväste (cookie) on pieni tekstitiedosto, joka tallennetaan tilapäisesti käyttäjän kiintolevylle. Evästeitä käytetään lähes kaikilla verkkosivustoilla, eivätkä sivustot välttämättä toimi kunnolla ilman evästeitä.",
      p2_3: "Eväste sisältää satunnaisesti luodun yksilöivän tunnuksen, jonka avulla voimme tunnistaa laitteesi ja kerätä tietoa siitä, mitä sivuja ja toimintoja verkkokaupassa käytät.",
      h21: "2.1. Evästeet ja tilastointi",
      p21_1: "Käytämme Google Analytics -ohjelmaa kerätäksemme tietoja siitä, miten verkkokauppaa käytetään. Tietojen avulla pyrimme saamaan käsityksen siitä, mitä verkkokaupan asiakkaat haluavat ja miten voimme parhaiten antaa asiakkaille hyvän käyttökokemuksen.",
      h22: "2.2. Evästeiden estäminen ja poistaminen",
      p22_1: "Voit estää evästeiden käytön laitteellasi muuttamalla selaimesi asetuksia. Asetusten sijainti riippuu selaimesta. Kannattaa kuitenkin ottaa huomioon, että monet toiminnot edellyttävät, että verkkokauppa tallentaa tekemäsi valinnat. Jos estät evästeet, et välttämättä voi käyttää kaikkia toimintoja."
    },
    termsModal: {
      title: "Toimitusehdot",
      h1: "Yleistä verkkokaupasta",
      p1_1: "Pyroll Kuitupakkaukset Oy (Y-tunnus: 0159902-7) myy PMPro -verkkokaupasta tuotteita yrityksille sekä täysi-ikäisille yksityishenkilöille. Asiakas sitoutuu jokaisen tilauksen teon yhteydessä voimassa oleviin toimitusehtoihin. Hyväksymällä tilaus- ja toimitusehdot asiakas hyväksyy, että asiakkaalle voidaan tarvittaessa lähettää sähköpostia tai soittaa tilaukseen liittyvissä asioissa.",
      p1_2_1: "Kaikki tilaukset vahvistetaan sähköpostiviestillä, Tilausvahvistuksen saaminen edellyttää sähköpostiosoitteen ilmoittamista tilauksen yhteydessä. Ota yhteyttä asiakaspalveluun,",
      p1_2_link: " pmpro{'@'}packagemedia.fi",
      p1_2_2: " mikäli et saa tilausvahvistusta lyhyen ajan kuluessa tilauksesi tekemisestä. Asiakkaan tulee tarkistaa tilausvahvistuksen oikeellisuus, sillä tuotteet toimitetaan tilausvahvistuksen mukaisesti. Tilausvahvistusviesti kannattaa säilyttää mahdollista myöhempää tarvetta varten. Kaikki asiakastiedot käsitellään luottamuksellisesti.",
      h2: "Arvonlisävero",
      p2_1: "Verkkokaupan tuotteisiin lisätään arvonlisävero 25.5%",
      h3:"Toimitustavat",
      p3_1: "Toimitamme tuotteet joko Postin express pakettina tai isommat määrät Postin rahtina. Toimituskulut sisältyvät tuotteen hintaan",
      h4:"Maksutavat",
      p4_1: "PMPro-verkkokaupan maksutapana on maksu kortilla tilauksen yhteydessä tai yritysasiakkaille lasku. Maksuehtona on 14 päivää netto. Viivästyskorko on 7 % + Suomen Pankin viitekorko. Yritysten on ilmoitettava Y-tunnus laskutusta varten. Palveluntarjoajalla on oikeus tarkistaa käyttäjän luottotiedot ennen tilauksen toimittamista. Kaikki tiedot käsitellään luottamuksellisesti.",
      h5:"Toimitusajat",
      p5_1: "Verkkokaupan yksilöllisesti painetut tilaukset toimitetaan 2 – 3 viikon kuluessa tilauksen saapumisesta. Varastotuotteet toimitetaan 2 – 3 päivän kuluessa tilauksesta. Mikäli tuote on hetkellisesti loppu varastostamme, otamme tilaajaan yhteyttä uuden toimitusajan sopimiseksi. Toimitusajan muuttuessa asiakkaalla on oikeus peruuttaa tilaus.",
      p5_2: "Pidätämme oikeuden kaupan perumiseen, mikäli tuote on kokonaan tai pidemmän aikaa loppuunmyyty, hinta on ilmoitettu ilmeisen virheellisesti tai jonkun muun ylivoimaisen esteen johdosta. Tällaisissa tapauksissa järjestelmä lähettää asiakkaalle tilausvahvistuksen, vaikka tuotetta ei olekaan saatavilla. PackageMedian asiakaspalvelu ottaa asiakkaaseen yhteyttä mahdollisimman nopeasti antaen asiakkaalle mahdollisuuden peruuttaa tilaus tai tarjoten korvaavaa tuotetta, mikäli sellainen on saatavilla. PackageMedia ei vastaa käyttämiensä kuljetusyritysten taikka kolmannen osapuolen kuljetuksiin liittyvistä tai muista viiveistä eikä asiakkaalle mahdollisesti aiheutuvista välillisistä vahingoista.",
      h6:"Reklamaatiot ja muut ongelmatilanteet",
      p6_1: "Mahdollisissa reklamaatioasioissa tai muissa ongelmatilanteissa pyydämme ottamaan viipymättä yhteyttä pmpro{'@'}packagemedia.fi tai puhelimitse 050 5320575. Asiakaspalvelumme on avoinna arkisin kello 8-16. Ilmoitus virheellisestä toimituksesta tai viallisesta tuotteesta on tehtävä 14 päivän sisällä toimituksen vastaanottamisesta.",
      h7:"Vaihto ja palautus",
      p7_1_1: "Yksityisasiakkailla on kuluttajasuojalain mukainen oikeus palauttaa verkkokaupan tilauksensa 14 vuorokauden kuluessa toimituksen vastaanottamisesta ilman erityistä syytä. Tämä ei kuitenkaan koske yksilöllisesti painettuja tilauksia. Ota aina yhteyttä ennen toimituksen palauttamista asiakaspalveluumme",
      p7_1_link: " pmpro{'@'}packagemedia.fi ",
      p7_1_2: "tai puhelimitse 050 5320575. Palautettavaan toimitukseen on kirjoitettava näkyvästi teksti ”palautus” sekä tilauskoodi.",
      p7_2: "Tuotteet tulee palauttaa käyttämättöminä, ehjinä, myyntikelpoisina ja alkuperäispakkauksissa. Asiakkaan on vastattava itse toimituksen palauttamisesta johtuvista välittömistä kustannuksista. Ennakkolähetyksiä emme lunasta. Palautus siirtyy saapumisen jälkeen tarkistettavaksi ja kun palautus on hyväksytty, maksetaan palautukseen liittyvä suoritus tilille. Voimme pidättyä maksujen palautuksesta, kunnes olemme saaneet toimituksen takaisin tai kunnes olette osoittanut lähettäneenne toimituksen takaisin. Emme vastaanota palautuksia yli 14 vuorokauden kuluttua toimituksen vastaanottamisesta. Mikäli haluat vaihtaa tilaamasi tuotteen toiseen, ole ystävällinen ja tee uusi tilaus.",
      h8:"Muuta",
      p8_1: "Pidätämme oikeuden muuttaa toimitusehtojamme. Asiakkaan on ennen tilaustaan tutustuttava kulloinkin voimassa oleviin toimitusehtoihin."
    },
    editorHelpModal: {
      h2_1: "Hei, tarvitsetko apua kupin suunnittelussa?",
      p_1: "Täältä löydät vastauksia ja vinkkejä:",
      a_1: "Usein kysyttyä",
      a_2: "Vinkkejä suunnitteluun",
      p_2: "Suunnittelupalvelu",
      p_3: "Voit myös antaa PMpron ammattilaisten suunnitella kuppisi. Lähetät vain aineistot meille.",
      span_1: "Lähetä aineistot",
      p_4: "Asiakaspalvelumme auttaa kaikissa kysymyksissä:",
    },
    newsletterThanksModal: {
      h2_1: "Kiitos!",
      p_1: "Lisäämme sinut uutiskirjeen tilaajaksi.",
    },
    orderResetModal: {
      p1: "Ostoskorisi tyhjentyi, koska kansien minimitilausmäärä ilman kuppeja on 1000 kpl"
    }
  }
}
