<template>
  <section class="hero is-fullheight terms-page">
    <PmNavbar />
    <div class="hero-body">
      <div class="container">
        <div class="columns light-bg is-multiline p-5">
          <div class="column is-12">
            <h2 class="mb-3 font-secondary">{{ this.$t("message.pages.termsPage.title") }}</h2>

            <h3 class="mt-5 font-secondary">{{ this.$t("message.pages.termsPage.h1") }}</h3>
            <p>
              {{ this.$t("message.pages.termsPage.p1_1") }}
            </p>
            <p class="mt-4">
                {{ this.$t("message.pages.termsPage.p1_2_1") }}
              <a href="mailto:pmpro@packagemedia.fi">{{ this.$t("message.pages.termsPage.p1_2_link") }}</a>
                {{ this.$t("message.pages.termsPage.p1_2_2") }}
            </p>

            <h3 class="mt-5 font-secondary">{{ this.$t("message.pages.termsPage.h2") }}</h3>
            <p>{{ this.$t("message.pages.termsPage.p2_1") }}</p>

            <h3 class="mt-5 font-secondary">{{ this.$t("message.pages.termsPage.h3") }}</h3>
            <p>
              {{ this.$t("message.pages.termsPage.p3_1") }}
            </p>

            <h3 class="mt-5 font-secondary">{{ this.$t("message.pages.termsPage.h4") }}</h3>
            <p>
              {{ this.$t("message.pages.termsPage.p4_1") }}
            </p>

            <h3 class="mt-5 font-secondary">{{ this.$t("message.pages.termsPage.h5") }}</h3>
            <p>
              {{ this.$t("message.pages.termsPage.p5_1") }}
            </p>
            <p class="mt-4">
              {{ this.$t("message.pages.termsPage.p5_2") }}
            </p>

            <h3 class="mt-5 font-secondary">{{ this.$t("message.pages.termsPage.h6") }}</h3>
            <p>
              {{ this.$t("message.pages.termsPage.p6_1") }}
            </p>

            <h3 class="mt-5 font-secondary">{{ this.$t("message.pages.termsPage.h8") }}</h3>
            <p>
              {{ this.$t("message.pages.termsPage.p8_1") }}
            </p>             
          </div>
        </div>
         
      </div>
    </div>
    <PmFooter />
  </section>  
</template>

<script>
import PmNavbar from "../components/PmNavbar";
import PmFooter from "../components/PmFooter";

export default {
  name: "TermsPage",
  components: {
    PmNavbar,
    PmFooter,
  },
  data() {
    return {}
  },
  methods: {},
  mounted() {},
  computed: {},
}
</script>

<style scoped lang="scss">
@use '../assets/sass/variables' as *;
.terms-page {
  .light-bg {
    background-color: $pm-light;
  }
}
</style>
