<template>
  <section class="hero is-fullheight method-page">
    <PmNavbar />
    <div class="hero-body is-align-items-flex-start light-bg">
      <div class="container">
        <div class="columns is-multiline py-5 white-bg">
          <div class="column is-12">
            <p
              class="has-text-centered cstm-font-color font-25 font-secondary cstm-underline"
            >
            {{ this.$t("message.pages.requestOrderPage.lahetaText") }}
            </p>
          </div>
          <div class="column is-12">
            <p class="has-text-centered cstm-font-color font-18">
              {{ this.$t("message.pages.requestOrderPage.taytaText") }}
            </p>
          </div>
        </div>       

        <div class="columns is-multiline white-bg mt-5 pb-5 mobile-content">
          <div class="column pl-0 is-4 is-offset-4 pb-5 cstm-bottom-border">
            <p class="font-secondary font-20">{{ this.$t("message.pages.requestOrderPage.valittuText") }}</p>
            <div class="control has-icons-right cstm-select">            
              <div class="select is-small">
                <select v-model="this.requestOrderForm.product" @change="resetProductAmount">
                  <option v-for="prod, idx in cups" :key="idx" :value="prod.id" class="font-open-sans">{{prod.name}}</option>
                </select>
              </div>
              <div class="icon is-small is-right" style="right:34px;top:2px;">
                <i class="fas fa-pen" style="color: #32a8b7; height: 24px"></i>
              </div>
            </div>          
          </div>

          <div
            class="column pl-0 is-4 is-offset-4 pb-5 cstm-bottom-border mt-5"
          >
            <p class="font-secondary font-20">{{ this.$t("message.pages.requestOrderPage.tilausText") }}</p>
            <div class="is-inline-block">
              <productAmount
                v-if="this.requestOrderForm.product!=null"
                ref="productAmount"
                :is-editable="true"
                v-model=this.requestOrderForm.amount
                :product=this.$store.getters.getProductById(this.requestOrderForm.product)[0]
                class="is-size-1"
              >
              </productAmount>
            </div>
          </div>

          <canvas id="bgImageCanvas" class="is-hidden" />

          <div
            class="column pl-0 is-4 is-offset-4 pb-5 cstm-bottom-border mt-5"
          >
            <p class="font-secondary font-20 mb-1">{{ this.$t("message.pages.requestOrderPage.taustaText") }}</p>
            <p class="font-open-sans font-15 mb-4">{{ this.$t("message.pages.requestOrderPage.sallitutText") }}</p>

            <div class="columns is-multiline">
              <div
                class="column is-3"
              >
                <label class="radio font-15 is-flex is-align-items-center font-open-sans">
                  <input class="cstm-radio-input mr-1" type="radio" name="bgvalue" v-model="bg" :value="'image'"/>
                  {{ this.$t("message.pages.requestOrderPage.kuvaText") }}
                </label>
              </div>
              <div class="column is-5">
                <input ref="addBgImageRef" style="display: none" type="file" accept="image/*" @change="bgImageChange"/> 
                <p class="font-13 font-open-sans" @click="uploadBgImage" :class="{'cstm-disabled': bg != 'image','is-clickable': bg == 'image'}">
                  <span class="mr-1">
                    <i
                      class="fa-solid fa-file-arrow-up"
                      style="color: #32a8b7; height: 24px"
                    ></i>
                  </span>
                  {{ this.$t("message.pages.requestOrderPage.valitseText_1") }}
                </p>
              </div>
              <div class="column is-4">
                <p class="font-13 is-clickabled font-open-sans" @click="deleteBgImg" :class="{'cstm-disabled': bg != 'image','is-clickable': bg == 'image'}">
                  <span class="mr-1">
                    <i
                      class="fa-solid fa-trash"
                      style="color: #32a8b7; height: 24px"
                    ></i>
                  </span>
                  {{ this.$t("message.pages.requestOrderPage.poistaText") }}
                </p>
              </div>

              <div class="column is-12 has-text-centered" v-if="this.requestOrderForm.bg_url || bgImageUrl">
                <img class="bgImagePreview" v-if="requestOrderForm.bg_url" :src="this.requestOrderForm.bg_url" />
                <img class="bgImagePreview" v-else :src="bgImageUrl" />
              </div>

              <div
                class="column is-3"
              >
                <label class="radio font-15 font-open-sans">
                  <input class="cstm-radio-input" type="radio" name="bgvalue" v-model="bg" :value="'solid'"/>
                  {{ this.$t("message.pages.requestOrderPage.variText") }}
                </label>
              </div>
              <div class="column is-5">
                <p class="font-13 font-open-sans" @click="showColorBgPicker" :class="{'cstm-disabled': bg != 'solid', 'is-clickable': bg == 'solid'}">
                  <span class="mr-1">
                    <i
                      class="fa-solid fa-palette"
                      style="color: #32a8b7; height: 24px"
                    ></i>
                  </span>
                  {{ this.$t("message.pages.requestOrderPage.valitseText_2") }}
                </p>
              </div>
              <div class="column is-4">

              </div>

              <div class="column is-12 m-0 p-0" v-if="bg == 'solid'">
                <div class="solidColorPreview m-auto" v-if="this.requestOrderForm.bg_color" :style="{'background-color':this.requestOrderForm.bg_color}"></div>
                <div class="solidColorPreview m-auto" v-else :style="{'background-color':this.backgroundColor}"></div>
              </div>

              <div class="bg-color-picker-wrapper" v-if=showBgColorPicker>
                <ColorPicker
                     :color="this.backgroundColor"
                     @color-change="updateBgColor"
                     :visible-formats="['hsl']"
                     alpha-channel="hide"
                   >
                </ColorPicker>
                <div class="has-text-centered">
                    <label for="cyan">C:</label>
                    <input type="range" v-model="bgColorCMYK.cyan" id="cyan" min="0" max="100" step="1">
                    <span>{{ bgColorCMYK.cyan }}</span>
                </div>
                <div class="has-text-centered">
                    <label for="magenta">M:</label>
                    <input type="range" v-model="bgColorCMYK.magenta" id="magenta" min="0" max="100" step="1">
                    <span>{{ bgColorCMYK.magenta }}</span>
                </div>
                <div class="has-text-centered">
                    <label for="yellow">Y:</label>
                    <input type="range" v-model="bgColorCMYK.yellow" id="yellow" min="0" max="100" step="1">
                    <span>{{ bgColorCMYK.yellow }}</span>
                </div>
                <div class="has-text-centered">
                    <label for="black">K:</label>
                    <input type="range" v-model="bgColorCMYK.black" id="black" min="0" max="100" step="1">
                    <span>{{ bgColorCMYK.black }}</span>
                </div>
                 <ColorPickerFooter
                  :bgColor="this.backgroundColor"
                  @cp-confirmButton="changeBgColor"
                  @cp-cancelButton="hideColorBgPicker"
                 />
               </div>              

              <div class="column">
                <label class="radio font-15 font-open-sans">
                  <input class="cstm-radio-input" type="radio" name="bgvalue" v-model="bg" :value="'none'"/>
                  {{ this.$t("message.pages.requestOrderPage.eitaustaaText") }}
                </label>
              </div>
            </div>
          </div>

          <canvas id="logoImageCanvas" class="is-hidden" />

          <div
            class="column pl-0 is-4 is-offset-4 pb-5 cstm-bottom-border mt-5"
          >
            <p class="font-secondary font-20 mb-1">{{ this.$t("message.pages.requestOrderPage.logoText") }}</p>
            <p class="font-15 mb-4 font-open-sans">{{ this.$t("message.pages.requestOrderPage.sallitutText") }}</p>

            <div class="columns is-multiline">
              <div class="column is-5">
                <form method="post" action="#" id="" enctype="multipart/form-data">
                  <div class="form-group files text-center" ref="fileform">
                      <input type="file" ref="file" multiple="multiple" id="logoupload" class="inputfile" @change="handleLogoUploader">
                      <label for="logoupload" class="logoupload">
                        <span class="mr-1">
                          <i
                            class="fa-solid fa-file-arrow-up"
                            style="color: #32a8b7; height: 24px"
                          ></i>
                        </span>
                        {{ this.$t("message.pages.requestOrderPage.valitseText_1") }}
                      </label>
                      <span id='val'></span>
                      <!--a class="btn" id='button'>Valitse tiedosto</a-->
                  </div>
                </form>

                <!--/p-->
              </div>
              <div class="column is-4">
                <p class="font-13 is-clickabled font-open-sans" @click="deleteLogoImg" :class="{'cstm-disabled': logos.length == 0,'is-clickable': logos.length > 0}">
                  <span class="mr-1">
                    <i
                      class="fa-solid fa-trash"
                      style="color: #32a8b7; height: 24px"
                    ></i>
                  </span>
                  {{ this.$t("message.pages.requestOrderPage.poistaText") }}
                </p>
              </div>

              <div class="column is-12 has-text-centered" v-for="(logo, idx) in logos_img" :key="idx">
                <img class="logoImagePreview" :src="logo" />
              </div>
              <div id="previewContainer"></div>
            </div>
          </div>

          <div class="column pl-0 is-4 is-offset-4 pb-5 mt-5 cstm-bottom-border">
            <p class="font-secondary font-20 mb-1">{{ this.$t("message.pages.requestOrderPage.tekstiText") }}</p>
            <div class="columns is-multiline">
              <div class="column is-12">
                <textarea
                  class="textarea cstm-txt-area font-open-sans"
                  placeholder="Kirjoita tähän kuppiin painettava teksti"
                  v-model="this.requestOrderForm.text"
                  @click="noText = False"
                ></textarea>
              </div>
              <div class="column is-4 pt-0">
                <label class="radio font-15 font-open-sans">
                  <input class="cstm-radio-input" type="radio" name="textValue" :checked="this.requestOrderForm.text.length == 0" @click="this.requestOrderForm.text = ''"/>
                  {{ this.$t("message.pages.requestOrderPage.eitekstiaText") }}
                </label>
              </div>
            </div>
          </div>

          <div class="column pl-0 is-4 is-offset-4 pb-5 mt-5">
            <p class="font-secondary font-20 mb-1">{{ this.$t("message.pages.requestOrderPage.ohjeetText") }}</p>
            <div class="columns is-multiline">
              <div class="column is-12">
                <textarea
                  class="textarea cstm-txt-area font-open-sans"
                  placeholder="Kirjoita tähän ohjeet asettelusta yms."
                  v-model="this.requestOrderForm.instructions"
                ></textarea>
              </div>
            </div>
          </div>          

          <div class="column pl-0 is-4 is-offset-4">
            <button class="button pm-button" @click="goToCart">{{ this.$t("message.pages.requestOrderPage.jatkaText") }}</button>
          </div>
        </div>
      </div>
    </div>
    <PmFooter />
  </section>
</template>

<script>
import PmNavbar from "../components/PmNavbar"
import PmFooter from "../components/PmFooter"
import productAmount from "@/components/productAmount"
import ColorPickerFooter from "@/components/ColorPickerFooter"
import colorPickerMixin from "@/mixins/colorPickerMixin"
import {ColorPicker} from 'vue-accessible-color-picker'
import { mapGetters, mapActions, mapMutations } from "vuex"
import convert from "color-convert"

export default {
  name: "DesignMethodPage",
  components: {
    PmNavbar,
    PmFooter,
    productAmount,
    ColorPickerFooter,
    ColorPicker
  },
  mixins: [colorPickerMixin],
  beforeMount() {
    this.getRequestOrderForm(this.$route.params.requestUuid).then(() => {
      this.logos_img = this.$store.state.getRequestOrderFormData.logos_img
      this.requestOrderForm = this.$store.state.getRequestOrderFormData
      if (this.requestOrderForm.bg_url == '' && this.requestOrderForm.bg_color != '') {
        this.bg = 'solid'
      }
    })
  },
  data() {
    return {
      requestOrderForm: {text: '', instructions: ''},
      bg: 'image',
      bgImage: null,
      bgImageUrl: null,
      showBgColorPicker: false,
      logo: 'none', // none, image
      logoImage: null,
      logoImageUrl: null,
      logos: [],
      logos_img: [],
      noText: false,
      bgColorCMYK: {
        cyan: 0,
        magenta: 0,
        yellow: 0,
        black: 100
      },
    };
  },
  methods: {
    ...mapGetters({
      getOrderData: "getOrderData",
      getProductById: "getProductById",
    }),
    ...mapActions({
      updateRequestOrderForm: "updateRequestOrderForm",
      getRequestOrderForm: "getRequestOrderForm",
      getOrder: "getOrder"
    }),
    ...mapMutations({
      setSpinnerState: 'setSpinnerState'
    }),
    uploadBgImage() {
      this.$refs.addBgImageRef.click()
    },
    bgImageChange(e) {
      let file = e.target.files[0]
      this.bgImage = file
      this.bgImageUrl = URL.createObjectURL(file)
      let img = new Image();
      img.onload = function () {
        let canvas = document.getElementById('bgImageCanvas');
        let ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height); // clear previous canvas
        canvas.width = this.width;
        canvas.height = this.height;        
        ctx.drawImage(this, 0,0);
      }
      img.onerror = function() {
        console.log("Error uploading image")
      }
      img.src = URL.createObjectURL(e.target.files[0]);
    },
    deleteBgImg() {
      this.bgImage = null
      this.bgImageUrl = null
      this.requestOrderForm.bg_url = null
    },
    deleteLogoImg() {
      this.logos = []
      const previewContainer = document.getElementById('previewContainer')
      previewContainer.innerHTML = ''
    },    
    showColorBgPicker() {
      this.showBgColorPicker = true
    },
    hideColorBgPicker() {
      this.showBgColorPicker = false
    },
    changeBgColor() {
      this.requestOrderForm.bg_color = this.backgroundColor
      this.showBgColorPicker = false
    },
    resetProductAmount(event) {
      let discounts = this.$store.getters.getVolumesDiscount(this.requestOrderForm.product)
      this.$refs.productAmount.productPrices = discounts
      this.$refs.productAmount.productAmount = discounts[0].volume_point
    },
    goToCart() {
      this.setSpinnerState(true)
      this.requestOrderForm.draft = false

      if(this.bgImage) {
        this.requestOrderForm.source_bg = document.getElementById('bgImageCanvas').toDataURL()
        this.requestOrderForm.bg_color = ''
      } else if(this.bg == "solid") {
        this.requestOrderForm.bg_color = this.backgroundColor
        // Remove image
        this.requestOrderForm.source_bg = ''
      } else {
        // Remove image
        this.requestOrderForm.bg_color = ''
        this.requestOrderForm.source_bg = ''
      }

      let formData = new FormData()
      for (let key in this.requestOrderForm) {
        formData.append(key, this.requestOrderForm[key]);
      }

      for(let i = 0; i < this.logos.length; i++ ){
        let file = this.logos[i]
        formData.append('logo', file)
      }

      this.updateRequestOrderForm(formData).then(() => {
        this.getOrder()
          .then(() => {
            this.setSpinnerState(false)
            this.$router.push({
              name: 'Cart',
              params: {
                uuid: this.$route.params.orderUuid
              }
            })
          })
      })
    },
    handleLogoUploader(event) {
      this.logos = event.target.files;

      const previewContainer = document.getElementById('previewContainer')

      previewContainer.innerHTML = ''

      for (let i = 0; i < this.logos.length; i++) {
        const reader = new FileReader()
        const imgElement = document.createElement('img')
        imgElement.style.width = '100px'

        reader.onload = function (e) {
          imgElement.src = e.target.result
        };

        reader.readAsDataURL(this.logos[i])

        previewContainer.appendChild(imgElement)
      }
    },
    updateBgColor (eventData) {
      let { h, s, l } = this.parseHSL(eventData.cssColor)
      let cmyk = this.hslToCmyk(h, s, l)
      this.bgColorCMYK.cyan = cmyk.cyan
      this.bgColorCMYK.black = cmyk.black
      this.bgColorCMYK.magenta = cmyk.magenta
      this.bgColorCMYK.yellow = cmyk.yellow
    },
  },
  computed: {
    backgroundColor() {
      let rgb = convert.cmyk.rgb([
          parseInt(this.bgColorCMYK.cyan),
          parseInt(this.bgColorCMYK.magenta),
          parseInt(this.bgColorCMYK.yellow),
          parseInt(this.bgColorCMYK.black)
        ]
      )
      const hex = convert.rgb.hex(rgb);
      return '#' + hex
    },
    cups() {
      return this.$store.getters.getProducts.filter(prod => {
        return prod.resource_type == 'Cup'
      })
    }
  },
  watch: {
    bg: function (new_val, old_val) {
      if (new_val == 'solid') {
        this.deleteBgImg()
      } else {
        this.requestOrderForm.bg_color = ''
      }
    },
  }
}
</script>

<style scoped lang="scss">
@use '../assets/sass/variables' as *;

.method-page {
  .cstm-font-color {
    color: $font-color-primary;
    .icon {
      width: 24px;
      height: 24px;
    }
  }
  .cstm-select {
    max-width: 177px;
    select {
      padding-left: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      text-overflow: '';      
      &:hover {
        border: none !important;
      }
    }
  }
  .white-bg {
    background-color: #ffffff;
  }
  .light-bg {
    background-color: #f0efe4;
  }

  .cstm-bottom-border {
    border-bottom: 1px solid #707070;
  }
  .cstm-txt-area {
    border: 1px solid #707070;
    resize: none;
    color: #707070;
    font-size: 15px;
  }
  .cstm-radio-input {
    width: 20px;
    height: 20px;
  }
  .bgImagePreview, .logoImagePreview {
    max-height: 200px;
  }
  .bg-color-picker-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border: 1px solid gray;
    background-color: white;
    z-index: 100;
  }
  .solidColorPreview {
    height: 25px;
    width: 80%;    
  }

  .cstm-disabled {
    opacity: 0.5;
  }
  input[type="radio"] {
    /* remove standard background appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* create custom radiobutton appearance */
    display: inline-block;
    width: 25px;
    height: 25px;
    padding: 1px;
    /* background-color only for content */
    background-clip: content-box;
    border: 2px solid #bbbbbb;
    background-color: #e7e6e7;
    border-radius: 50%;
  }
  input[type="radio"]:checked {
    background-color: $pm-blue;
  }  
}

.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.logoupload:hover {
  cursor: pointer;
}

.mobile-content {
  @media (max-width: 768px) {
    padding-left: 30px;
  }
}
</style>
