<template>
  <teleport to="#modals">
    <div class="modal" :class="{ 'is-active': active }">
      <div class="modal-background" @click="this.closeModal()"></div>
      <div class="modal-content p-4 container">
        <img
          src="../assets/icons/close.svg"
          class="is-pulled-right close-button"
          @click="this.closeModal()"
        />
        <div class="is-clearfix"></div>

        <div class="columns bg-pm-blue">
          <div class="column is-12">
            <h2 class="mb-3 font-secondary font-28">{{ this.$t("message.modals.newsletterThanksModal.h2_1") }}</h2>
            <p class="font-18">{{ this.$t("message.modals.newsletterThanksModal.p_1") }}</p>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
export default {
  name: "NewsletterThanksModal",
  components: {
  },
  data() {
    return {
      active: false,
    };
  },
  methods: {
    closeModal() {
      this.active = false;
    },
    showModal() {
      this.active = true;
    },
  },
};
</script>

<style scoped lang="scss">
@use '../assets/sass/variables' as *;
.modal {
  .modal-content {
    
  }
  
  .close-button {
    cursor: pointer;
    color: #FFFFFF;
  }

  .bg-pm-blue {
    background-color: $pm-blue;
  }

  h2 {
    color: #FFFFFF;
  }
  p {
    color: #FFFFFF;
  }

}

</style>
