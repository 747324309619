<template>
  <section class="hero is-fullheight cart-page">
    <PmNavbar />
      <asModalDialog ref="deleteProduct" heading-text="Oletko varma?" ok-button-text="Kylla"
      cancel-button-text="Ei">
      
        <p>{{ this.$t("message.pages.cartPage.deleteProductModalText") }}</p>
      </asModalDialog>
      <asModalDialog
        heading-text="3d esikatselu"
        :show-ok="false"
        :show-cancel="false"
        width="80%"
        min-width="500px"
        ref="previewModal"
        class="is-marginless"
      >
        <preview3d :textureWidth=1000 :textureHeight=400 :gblVariable="'Exterior'" :texture="texture" :uri="currentTextureUri"/>
      </asModalDialog>

    <div class="hero-body light-bg">
      <div class="container">
        <div class="columns is-multiline p-5 white-bg">
          <div class="column is-12">
            <h3 class="has-text-centered cstm-underline custom-black font-secondary">{{ this.$t("message.pages.cartPage.title") }}</h3>
          </div>
        </div>

        <div class="columns is-multiline mt-5 white-bg">
          <div class="column is-10 is-offset-1 has-text-centered-mobile has-text-right-tablet">
            <button class="button pm-small-button my-5" @click="goToProductList()">{{ this.$t("message.pages.cartPage.goProductListButtonText") }}</button>
          </div>

          <!-- Start Cart Line -->
          <div v-for="(product, index) in not_draft_products" :key="index" class="column is-10 is-offset-1 border-bottom px-5">
            <div class="columns">
              <div class="column is-one-fifth has-text-centered">
                <figure class="image" v-if="product.thumbnail_url != ''">
                  <img :src="product.thumbnail_url">
                </figure>
                <figure class="image" v-else-if="product.product.thumbnail != ''">
                  <img :src="product.product.thumbnail" style="height: 150px; width: auto; margin-right: auto; margin-left: auto;">
                </figure>
                <figure class="image" v-else>
                  <img src="../assets/img/cup_6oz.png">
                </figure>
                <p class="font-18 font-open-sans">{{getProductName(product.product.id)}}</p>
              </div>

              <div class="column is-one-fifth is-flex is-justify-content-center is-align-items-center">
                  <productAmount
                      :is-editable="true"
                      v-model="product.amount"
                      :product="product.product"
                      :item="product"
                      class="is-size-5"
                      @amountupdated="updateAmount"
                  >
                  </productAmount>
              </div>

              <div class="column is-one-fifth has-text-centered is-hidden-mobile is-flex is-justify-content-center is-align-items-center">
                <span class="font-18 font-open-sans">{{ this.$t("message.pages.cartPage.costPrepText") }} {{ getVolumeDiscountPerCupSize(product.product.id) }} €</span>
              </div>

              <div class="column is-one-fifth has-text-centered is-flex is-align-items-center is-justify-content-space-around">

                <span class="is-clickable" @click="openPreview(product)" v-if="product.product.resource_type == 'Cup'">
                  <i class="fas fa-eye"></i>
                </span>

                <span class="is-clickable" @click="editProduct(product)" v-if="product.product.resource_type == 'Cup'">
                  <i class="fas fa-pen"></i>
                </span>

                <span class="is-clickable" @click="deleteProduct(product)">
                  <i class="fas fa-trash-alt"></i>
                </span>
              </div>

            </div>
          </div>
          <div v-for="(form, index) in non_draft_forms" :key="index" class="column is-10 is-offset-1 border-bottom px-5">
            <div class="columns">
              <div class="column is-one-fifth has-text-centered">
                <figure class="image">
                  <img v-if="form.bg_url == ''" src="../assets/img/cup_6oz.png">
                  <img v-else :src="form.bg_url">
                </figure>
                <p class="font-18">{{getProductName(form.product)}}</p>
              </div>

              <div class="column is-one-fifth is-flex is-justify-content-center is-align-items-center">
                  <productAmount
                      :is-editable="true"
                      v-model="form.amount"
                      :product="this.$store.getters.getProductById(form.product)[0]"
                      :item="form"
                      class="is-size-5"
                      @amountupdated="updateRequestOrderAmount"
                  >
                  </productAmount>                
              </div>

              <div class="column is-one-fifth has-text-centered is-hidden-mobile is-flex is-justify-content-center is-align-items-center">
                <span class="font-18">{{ this.$t("message.pages.cartPage.costPrepText") }} {{ getVolumeDiscountPerCupSize(form.product) }} €</span>
              </div>
              <div class="column is-one-fifth has-text-centered is-hidden-mobile is-flex is-justify-content-center is-align-items-center">
                <span class="font-18">{{ this.$t("message.pages.cartPage.suunniText") }} {{this.$store.getters.getDesignServiceFee.price}}€</span>
              </div>
              <div class="column is-two-fifth is-hidden-tablet is-flex is-align-items-center is-justify-content-space-around">
                <span class="font-18">{{ this.$t("message.pages.cartPage.costPrepText") }} {{ getVolumeDiscountPerCupSize(form.product) }} €</span>
                <span class="font-18">{{ this.$t("message.pages.cartPage.suunniText") }} {{this.$store.getters.getDesignServiceFee.price}}€</span>
              </div>

              <div class="column is-one-fifth has-text-centered is-flex is-align-items-center is-justify-content-space-around">

                <span class="is-clickable is-invisible">
                  <i class="fas fa-eye"></i>
                </span>

                <span class="is-clickable" @click="editForm(form)">
                  <i class="fas fa-pen"></i>
                </span>

                <span class="is-clickable" @click="deleteForm(form)">
                  <i class="fas fa-trash-alt"></i>
                </span>
              </div>
              
            </div>
          </div>
          <!-- End Cart Line -->

          <!-- Start amount by cup sizes -->
          <div class="column is-10 is-offset-1">
            <div class="columns">
              <div class="column has-text-centered">
                <template v-for="(cup, idx) in this.$store.getters.getProducts" :key="idx">
                  <p class="font-18 font-open-sans has-text-weight-bold" v-if="this.$store.getters.getItemsTotalAmountByProduct(cup.id) > 0">{{cup.name}}: {{ this.$store.getters.getItemsTotalAmountByProduct(cup.id) }} {{ this.$t("message.pages.cartPage.eachPieceText") }} {{ this.$t("message.pages.cartPage.costPrepText") }} {{ getVolumeDiscountPerCupSize(cup.id) }} € = {{(getVolumeDiscountPerCupSize(cup.id) * this.$store.getters.getItemsTotalAmountByProduct(cup.id)).toFixed(2)}} €</p>
                </template>
              </div>
            </div>
          </div>
          <!-- End amount by cup sizes -->          

          <!-- Start total amount -->
            <div class="column is-10 is-offset-1">
              <div class="columns">
                <div class="column has-text-centered">
                  <p class="font-open-sans has-text-weight-bold font-18" v-if="non_draft_forms.length > 0">{{ this.$t("message.pages.cartPage.suunniText") }} {{non_draft_forms.length}} x {{this.$store.getters.getDesignServiceFee.price}} € = {{getDesignServiceTotalFee}} €</p>
                  
                  <span class="font-open-sans has-text-weight-bold font-18">{{ this.$store.getters.getItemsTotalAmount }} {{ this.$t("message.pages.cartPage.eachPieceText") }}</span>
                  <span class="font-18 font-open-sans has-text-weight-bold"> = {{ getTotalPrice }} €</span>
                </div>
              </div>
            </div>                
          <!-- End total amount -->

          <!-- Start total amount with VAT-->
            <div class="column is-10 is-offset-1">
              <div class="columns">
                <div class="column has-text-centered">
                  <span class="font-open-sans has-text-weight-bold font-18">{{ this.$t("message.pages.cartPage.taxText") }} 25.5%</span>
                  <span class="font-18 font-open-sans has-text-weight-bold"> = {{ ((parseFloat(getDesignServiceTotalFee) + parseFloat(getTotalPrice)) * 1.255).toFixed(2) }} €</span>
                </div>                  
              </div>
            </div>         
          <!-- End total amount with VAT-->          

          <!-- Start Contact form -->
          <div class="column is-10 is-offset-1 mt-5 pt-5">
            <!--div class="columns">
              <div class="column is-12">
                <div class="control is-flex is-justify-content-space-evenly">
                  <label class="radio font-open-sans">
                    <input type="radio" id="two" :value="true" v-model="contact.b2b" />
                    {{ this.$t("message.pages.cartPage.radioTextTwo") }}
                  </label>
                </div>
              </div>
            </div-->
            <div class="columns">
              <div class="column is-12">
                <h4 class="font-secondary custom-black">
                  {{ this.$t("message.pages.cartPage.contactHeadingOne") }}
                </h4>
              </div>
            </div>
            <div class="columns">
              <div class="column is-8">
                <input
                    class="input pm-input"
                    type="text"
                    :placeholder="getRecvNamePlaceholderTranslation"
                    v-model=contact.recv_name
                >
                <p v-if="v$.recv_name.$error" class="help is-danger">{{ this.$t("message.pages.cartPage.contactRequiredFieldText") }}</p>
              </div>
            </div>
            <div v-show="contact.b2b" class="columns">
              <div class="column is-8">
                <input
                    class="input pm-input"
                    type="text"
                    placeholder="Y-tunnus *"
                    v-model=contact.cust_vat_nr
                >
                <p v-if="v$.cust_vat_nr.$error" class="help is-danger">{{ this.$t("message.pages.cartPage.ytunnusText") }}</p>
              </div>
            </div>
            <div v-show="contact.b2b"  class="columns">
              <div class="column is-8">
                <input
                    class="input pm-input"
                    type="text"
                    placeholder="Yhteyshenkilö"
                    v-model=contact.recv_company
                >
                <p v-if="v$.recv_company.$error" class="help is-danger">{{ this.$t("message.pages.cartPage.contactRequiredFieldText") }}</p>
              </div>
            </div>
            <div class="columns">
              <div class="column is-8">
                <input
                    class="input pm-input"
                    type="text"
                    placeholder="Puhelinnumero"
                    v-model=contact.recv_phone
                >
                <p v-if="v$.recv_phone.$error" class="help is-danger">{{ this.$t("message.pages.cartPage.contactRequiredFieldText") }}</p>
              </div>
            </div>

            <div class="columns">
              <div class="column is-8">
                <input
                    class="input pm-input"
                    type="text"
                    placeholder="Sähköpostiosoite"
                    v-model=contact.cust_email
                >
                <p v-if="v$.cust_email.$error" class="help is-danger">{{ this.$t("message.pages.cartPage.contactRequiredFieldText") }}</p>
              </div>
            </div>

            <div class="columns">
              <div class="column is-8">
                <input
                    class="input pm-input"
                    type="text"
                    placeholder="Katuosoite"
                    v-model=contact.recv_street
                >
                <p v-if="v$.recv_street.$error" class="help is-danger">{{ this.$t("message.pages.cartPage.contactRequiredFieldText") }}</p>
              </div>
            </div>
            <div class="columns">
              <div class="column is-3">
                <input
                    class="input pm-input"
                    type="text"
                    placeholder="Postinumero"
                    v-model=contact.recv_zip
                >
                <p v-if="v$.recv_zip.$error" class="help is-danger">{{ this.$t("message.pages.cartPage.contactRequiredFieldText") }}</p>
              </div>
              <div class="column is-5">
                <input
                    class="input pm-input"
                    type="text"
                    placeholder="Postitoimipaikka"
                    v-model=contact.recv_town
                >
                <p v-if="v$.recv_town.$error" class="help is-danger">{{ this.$t("message.pages.cartPage.contactRequiredFieldText") }}</p>
              </div>
            </div>
            <div v-if="contact.b2b" class="columns">
              <div class="column">
                <label class="checkbox">
                  <input type="checkbox" v-model="need_billing_address">
                  {{ this.$t("message.pages.cartPage.checkboxBillText") }}
                </label>
              </div>
            </div>
            <div v-if="need_billing_address && contact.b2b" class="columns">
              <div class="column is-8">
                <input
                    class="input pm-input"
                    type="text"
                    placeholder="Vastaanottajan nimi"
                    v-model=contact.bill_company
                >
                <p v-if="v$.bill_company.$error" class="help is-danger">{{ this.$t("message.pages.cartPage.contactRequiredFieldText") }}</p>
              </div>
            </div>
            <div v-if="need_billing_address && contact.b2b" class="columns">
              <div class="column is-8">
                <input
                    class="input pm-input"
                    type="text"
                    placeholder="Yhteyshenkilö"
                    v-model=contact.bill_name
                >
                <p v-if="v$.bill_name.$error" class="help is-danger">{{ this.$t("message.pages.cartPage.contactRequiredFieldText") }}</p>
              </div>
            </div>
            <div v-if="need_billing_address && contact.b2b" class="columns">
              <div class="column is-8">
                <input
                    class="input pm-input"
                    type="text"
                    placeholder="Puhelinnumero"
                    v-model=contact.bill_phone
                >
                <p v-if="v$.bill_phone.$error" class="help is-danger">{{ this.$t("message.pages.cartPage.contactRequiredFieldText") }}</p>
              </div>
            </div>

            <div v-if="need_billing_address && contact.b2b" class="columns">
              <div class="column is-8">
                <input
                    class="input pm-input"
                    type="text"
                    placeholder="Katuosoite"
                    v-model=contact.bill_street
                >
                <p v-if="v$.bill_street.$error" class="help is-danger">{{ this.$t("message.pages.cartPage.contactRequiredFieldText") }}</p>
              </div>
            </div>
            <div v-if="need_billing_address && contact.b2b" class="columns">
              <div class="column is-8">
                <input
                    class="input pm-input"
                    type="text"
                    placeholder="Postinumero"
                    v-model=contact.bill_zip
                >
                <p v-if="v$.bill_zip.$error" class="help is-danger">{{ this.$t("message.pages.cartPage.contactRequiredFieldText") }}</p>
              </div>
            </div>
            <div v-if="need_billing_address && contact.b2b" class="columns">
              <div class="column is-8">
                <input
                    class="input pm-input"
                    type="text"
                    placeholder="Postitoimipaikka"
                    v-model=contact.bill_town
                >
                <p v-if="v$.bill_town.$error" class="help is-danger">{{ this.$t("message.pages.cartPage.contactRequiredFieldText") }}</p>
              </div>
            </div>                                                
          </div>

          <div class="column is-10 is-offset-1">
            <div class="columns is-multiline is-mobile">
              <div class="column is-12">
                <label class="checkbox">
                  <input type="checkbox" v-model="terms">
                  {{ this.$t("message.pages.cartPage.salesTermsText") }}
                </label> <a @click="$router.push({name: 'TermsPage'})">{{ this.$t("message.pages.cartPage.salesTermsClickText") }}.</a>
                
                <p v-if="termsError" class="help is-danger">{{ this.$t("message.pages.cartPage.salesTermsErrorText") }}</p>
              </div>

              <div class="column is-12">
                <label class="checkbox">
                  <input type="checkbox" v-model="contact.marketing">
                  {{ this.$t("message.pages.cartPage.marketingText") }}
                </label>
              </div>

            </div>
          </div>

          <div class="column is-10 is-offset-1 has-text-center-mobile has-text-left-tablet">
            <button class="button pm-small-button my-5" @click="submitContact()">{{ this.$t("message.pages.cartPage.submitButtonText") }}</button>
          </div>
                                           
          <!-- End Contact form -->
        </div>
      </div>
    </div>
    <PmFooter />
  </section>
</template>

<script>
import PmNavbar from '../components/PmNavbar'
import PmFooter from '../components/PmFooter'
import cartMixin from "@/mixins/cartMixin"
import productAmount from "@/components/productAmount"
import asModalDialog from "@/helpers/asModalDialog"
import {mapActions, mapMutations, mapGetters, useStore} from "vuex"
import { useVuelidate } from '@vuelidate/core'
import { reactive, ref } from 'vue'
import {email, required, requiredIf } from '@vuelidate/validators'
import preview3d from "@/3d/preview3d"

export default {
  components: {
    PmNavbar,
    PmFooter,
    productAmount,
    asModalDialog,
    preview3d
  },
  mixins: [cartMixin],
  setup: () => {
    const need_billing_address = ref(false)
    const dataUrl = ref(null)
    const currentTextureUri = ref(null)
    const isCompany = ref(false)
    const terms = ref(false)
    const termsError = ref(false)
    const store = useStore()
    let contactData = {
      recv_name: '',
      recv_company: '',
      recv_phone: '',
      recv_street: '',
      recv_zip: '',
      recv_town: '',

      bill_name: '',
      bill_company: '',
      bill_phone: '',
      bill_street: '',
      bill_zip: '',
      bill_town: '',

      cust_email: '',
      cust_vat_nr: '',
      cust_contact: '',
      marketing: false,
      b2b: true,
    }
    if(store.getters.getContactData){
      contactData = store.getters.getContactData
    }
    const contact = reactive(contactData)

    const billing_func_validator = new_billing_address => (new_billing_address)

    const rules = {
      recv_name: {
        required,
      },
      recv_company: {
        required: requiredIf((value, rules) => rules.b2b)
      },
      recv_phone: {
        required
      },
      recv_street: {required},
      recv_zip: {required},
      recv_town: {required},
      cust_email: {
        email,
        required,
      },
      cust_vat_nr: {
        required: requiredIf((value, rules) => rules.b2b)
      },
      bill_name: {
        required: requiredIf(billing_func_validator(need_billing_address))
      },
      bill_company: {
        required: requiredIf(billing_func_validator(need_billing_address))
      },
      bill_phone: {
        required: requiredIf(billing_func_validator(need_billing_address))
      },
      bill_street: {
        required: requiredIf(billing_func_validator(need_billing_address))
      },
      bill_zip: {
        required: requiredIf(billing_func_validator(need_billing_address))
      },
      bill_town: {
        required: requiredIf(billing_func_validator(need_billing_address))
      },
      cust_contact: {},
      marketing: {},
      b2b: {}
    }



    const v$ = useVuelidate(rules, contact)

    return {
      need_billing_address,
      dataUrl,
      isCompany,
      contact,
      currentTextureUri,
      terms,
      termsError,
      v$,
    }
  },
  mounted() {
    this.need_billing_address = this.$store.state.billing
    window.onbeforeunload = function() {
      var message = 'Do you want to leave this page?';
      return message;
    }
  },
  methods: {
    ...mapActions({
      deleteItem: 'deleteItem',
      getOrder: 'getOrder',
      updateItem: 'updateItem',
      updateRequestOrderForm: 'updateRequestOrderForm',
      deleteRequestOrderForm: 'deleteRequestOrderForm'
    }),
    ...mapMutations({
      setSpinnerState: 'setSpinnerState',
      setReseting: 'setReseting',
      setResetOrderNotificationShow: 'setResetOrderNotificationShow'
    }),
    ...mapGetters([
      'getProducts',
      'getOrderData',
      'getSavedItems',
      'getNonDraftOrderForms',
    ]),
    getProductName(product_id) {
      return this.getProducts().find(p => p.id == product_id).name
    },
    openTermsModal() {
      window.scrollTo(0, 0);
      this.$refs.termsModal.showModal()
    },
    submitContact() {
      window.onbeforeunload = null; 
      this.v$.$touch()
      if (this.v$.$error) return

      if (!this.terms) {
        this.termsError = true
        return
      } else {
        this.termsError = false
      }

      const contact_wo = Object.assign({order: this.getOrder().id}, this.contact)

      this.setSpinnerState(true)

      let mutation = 'postContact'

      if (this.$store.state.postContactData != null) {
        mutation = 'updateContact'
      }

      this.$store.dispatch(mutation, contact_wo).then(() => {
        this.$router.push({name: 'PaymentPage', params: {uuid: this.getOrderData().id}})
      }).catch((error) => {
        // FIXME: doAsync handles the catch of axios call, we are never getting to here
        console.log('Error saving contact', error)
      }).finally(() => {
        this.setSpinnerState(false)
      })
    },
    goToProductList() {
      this.$router.push({
        name: 'ShopList'
      })
    },
    deleteProduct(item) {
      /*
      Possible conditions
      - If there is only one product and this is remove then we redirect to landing page to dont render an empty cart
      - If the item deleted is the LAST cup AND the order has lids then the lids price needs to be recalcualted, reset????
      - In any other case NOTHING happens
      * */
      let lids = this.has_lids
      this.$refs.deleteProduct.showModal()
         .then(() => {
            this.deleteItem(item.id)
              .then(() => {
                this.getOrder()
                  .then(() => {
                    if (!this.has_cups && lids) {
                      // if there are only lids left then the order needs to be reset because lids price changes depending whether the order has cups or not
                      this.setResetOrderNotificationShow(true)
                      this.setReseting(true)
                      this.$router.push({name: 'Landing'})
                    } else if (this.not_draft_products.length == 0) {
                      this.setReseting(true)
                      this.$router.push({name: 'Landing'})
                    }
                  })
              })
         })
         .catch(() => {
           console.log('')
         })
    },
    editProduct(item) {
      this.$router.push({
          name: 'Editor',
          params: {
              uuid: this.getOrderData().id,
              product: item.product.id,
              item: item.id
          }
      })
    },
    deleteForm(form) {
      this.$refs.deleteProduct.showModal().then(() => {
        this.deleteRequestOrderForm(form.id).then(() => {
          this.getOrder().then(() => {
              // if no items return to landing page
              if (this.getSavedItems().length > 0 || this.getNonDraftOrderForms().length > 0) {
                // this.$router.go() // reload the page
              } else {
                this.setReseting(true)
                this.$router.push({name: 'Landing'})
              }            
          })
        })
      }).catch(() => {

      })
    },
    editForm(form) {
      this.$router.push({
        name: 'RequestOrderFormPage',
        params: {
          orderUuid: this.getOrderData().id,
          requestUuid: form.id,
          productId: form.product
        }
      })
    },
    getTextureUri(currentProduct) {
      let path = ''
      if(currentProduct.product.name.includes('(6 oz)')){
        path = `${process.env.BASE_URL}3d/paper-cup-6oz-v5.glb`
      }
      if(currentProduct.product.name.includes('(8 oz)')){
        path = `${process.env.BASE_URL}3d/paper-cup-8oz-v5.glb`
      }
      if(currentProduct.product.name.includes('(12 oz)')){
        path =  `${process.env.BASE_URL}3d/paper-cup-12oz-v4.glb`
      }
      if(currentProduct.product.name.includes('(16 oz)')){
        path =  `${process.env.BASE_URL}3d/paper-cups-16oz-v1.glb`
      }
      if(currentProduct.product.name.includes('(20 oz)')){
        path =  `${process.env.BASE_URL}3d/paper-cup-20oz-v5.glb`
      }
      return path;
    },    
    openPreview(product) {
      this.currentTextureUri = this.getTextureUri(product)
      this.dataUrl = product.texture_url
      this.$refs.previewModal.showModal()
    },
    updateAmount(item, amount) {
      this.updateItem(
        {
          'id': item,
          'amount': amount
        }
      )
    },
    updateRequestOrderAmount(id, amount) {
      let formData = new FormData()
      formData.append('id', id)
      formData.append('amount', amount)
      this.updateRequestOrderForm(formData)
    }
  },
  computed: {
    texture() {
      return [{
        dataUrl: this.dataUrl,
        posx: 0,
        posy: 0,
        width: 1000,
        height: 400,
        rotate: 0
      }]
    },
    getRecvNamePlaceholderTranslation() {
      return this.$t("message.pages.cartPage.recvNamePlaceholder")
    }
  },
}
</script>

<style scoped lang="scss">
@use '../assets/sass/variables' as *;
.cart-page {
  .container {
    padding-left: 12px;
    padding-right: 12px;
  }

  .custom-black {
    color: $font-color-primary;
  }

  .white-bg {
    background-color: #ffffff;
  }

  .light-bg {
    background-color: #f0efe4;
  }
  .amount-input {
    width: 66px;
    height: 31px;
    background-color: #DEDBD9;
  }
  .border-bottom {
    border-bottom: 1px solid #707070;
  }
  .visibility-hidden {
    visibility: hidden;
  }

  // Remove arrows in number input type
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .svg-inline--fa {
    cursor: pointer;
  }

  .mandatory-fields-text {
    color: #DEDBD9;
  }

  .pm-input {
    border: 1px solid #707070 !important;
    height: 55px;
    font-size: 18px;
    font-family: open-sans, sans-serif;
    font-weight: 400;
    font-style: normal;
    &::placeholder {
      font-family: open-sans, sans-serif;
      font-weight: 400;
      font-style: normal;
      color: #707070;
    }    
  }
}

span.is-clickable {
  color: $pm-blue;
  svg {
    width: 20px;
    height: 20px;
  }
}

.text-wrapper {
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
