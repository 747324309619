<template>
  <div>
    <div class="columns is-mobile is-multiline is-hidden-tablet">
      <div v-if="isEditable" class="column is-2">
        <span class="icon minus-icon mr-2" :class="{'icon-disabled': minAmountLimit}" @click="decreaseAmount()">
          <i class="fas fa-minus-circle"></i>
        </span>
      </div>
      <div class="column is-8">
        <span class="has-text-centered amount">x {{ productAmount}}</span>
      </div>
      <div v-if="isEditable" class="column is-2">
        <span class="icon plus-icon ml-2" :class="{'icon-disabled': maxAmountLimit}" @click="increaseAmount()">
          <i class="fas fa-plus-circle"></i>
        </span>
      </div>
    </div>

    <div class="columns is-multiline is-hidden-mobile">
      <div class="column is-2">
        <span v-if="isEditable" class="icon minus-icon mr-2" :class="{'icon-disabled': minAmountLimit}" @click="decreaseAmount()">
          <i class="fas fa-minus-circle"></i>
        </span>
      </div>
      <div class="column is-8">
        <span class="has-text-centered amount">x {{ productAmount}}</span>
      </div>
      <div class="column is-2">
        <span v-if="isEditable" class="icon plus-icon ml-2" :class="{'icon-disabled': maxAmountLimit}" @click="increaseAmount()">
          <i class="fas fa-plus-circle"></i>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "productAmount",
  props: ['modelValue', 'isEditable', 'product', 'id', 'item'],
  data(){
    return {
      productAmount: this.modelValue,
      productPrices: this.$store.getters.getVolumesDiscount(this.product.id)
    }
  },
  methods: {
    ...mapGetters({
      getVolumesDiscount: 'getVolumesDiscount',
      getProductById: 'getProductById',
      getSavedItems: 'getSavedItems'
    }),
    increaseAmount() {
      if (this.product.resource_type == 'Lid') {
        this.productAmount = this.item.amount + 100
        this.$emit('update:modelValue', this.productAmount)
        if (this.item != null) {
          this.$emit('amountupdated', this.item.id, this.productAmount)
        }
      } else {
        let currentIndex = this.productPrices.findIndex(a => a.volume_point == this.productAmount)
        if (this.productPrices[currentIndex + 1]) {
          this.productAmount = this.productPrices[currentIndex + 1].volume_point
          this.$emit('update:modelValue', this.productAmount)
          if (this.item != null) {
            this.$emit('amountupdated', this.item.id, this.productAmount)
          }
        } else {
          // Max amount reached
        }
      }
    },
    decreaseAmount() {
      if (this.product.resource_type == 'Lid') {
        if (this.item.amount - 100 >= 0) {
          this.productAmount = this.item.amount - 100
          this.$emit('update:modelValue', this.productAmount)
          if (this.item != null) {
            this.$emit('amountupdated', this.item.id, this.productAmount)
          }
        }
      } else {
        let currentIndex = this.productPrices.findIndex(a => a.volume_point == this.productAmount)
        if (this.productPrices[currentIndex - 1]) {
          this.productAmount = this.productPrices[currentIndex - 1].volume_point
          this.$emit('update:modelValue', this.productAmount)
          if (this.item != null) {
            this.$emit('amountupdated', this.item.id, this.productAmount)
          }
        } else {
          // Min amount reached
        }
      }
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    maxAmountLimit() {
      if (this.product.resource_type == 'Lid') { // lid doesnt have max amount
        return false
      }
      let currentIndex = this.productPrices.findIndex(a => a.volume_point == this.productAmount)
      if (currentIndex + 1 == this.productPrices.length) {
        // Max amount
        return true
      }
      return false
    },
    minAmountLimit() {
      if (this.product.resource_type == 'Lid' && !this.has_cups && this.productAmount == 1000) { // lid doesnt have max amount
        return true
      }
      if (this.product.resource_type == 'Lid' && this.has_cups && this.productAmount == 50) { // lid doesnt have max amount
        return true
      }
      let currentIndex = this.productPrices.findIndex(a => a.volume_point == this.productAmount)
      if (currentIndex == 0) {
        // Min amount
        return true
      }
      return false
    },
    has_cups() {
      for (let i = 0; i < this.getSavedItems().length; i++) {
        if (this.getSavedItems()[i].product.resource_type == 'Cup') {
          return true
        }
      }
      return false
    }
  }
}
</script>

<style scoped lang="scss">

@use '../assets/sass/variables' as *;

.amount {
  display: inline-block;
  min-width: 3em;
  background-color: white;
  border-radius: 1px;
  border: 1px solid #707070;
  font-size: 18px;
  font-family: "mokokoregular";
  width: 100%;
}

.plus-icon {
  left: -24px;
}

.icon {
  position: relative;
  top: 3px;
}

.icon.minimum-required {
  position: relative;
  top: 77px;
}

.icon:hover {
  cursor: pointer;
  color: #f4acbc;
}

.red {
  color: red !important;
}

.bubble {
  position: absolute;
  padding: 5px 10px 5px 10px;
  background: #FFFFFF;
  border: 1px solid red;
  font-size: 10px;
  color: red;
  top: -15px;
  right: 60px;
  @media only screen and (min-width: 1100px) {
    right: 11px;
  }
  @media screen and (max-width: 1215px) and (min-width: 768px) {
    right: 44px;
    font-size: 12px;
  }
}

span {
  svg {
    color: $pm-blue;
    font-size: 30px;
  }
}

.icon-disabled {
  cursor: not-allowed !important;
  svg {
    color: #dddddd;
  }
}
</style>
