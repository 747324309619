<template>
  <section class="hero is-fullheight shop-list-page">
    <PmNavbar />
    <div class="hero-body bg-light">
      <div class="container">

        <div class="columns mb-5 px-3">
          <div class="column is-12 bg-white py-5">
            <h3 class="has-text-centered font-secondary custom-black cstm-underline pt-5 pb-5">
              {{ this.$t("message.pages.shopListPage.headingText") }}
            </h3>
            <p class="has-text-centered font-18 custom-black pb-5">
              {{ this.$t("message.pages.shopListPage.description") }}
            </p>
          </div>
        </div>

        <div class="columns py-5 is-multiline mt-5 bg-light">
          <div class="column is-6" v-for="(product, index) in $store.getters.getCups" :key="index">
            <div class="cup-card m-auto columns" v-if="product.resource_type == 'Cup'">
              <div class="column is-6 pb-0">
                <div class="cup-image is-align-items-center">
                  <figure class="image">
                    <img :src="product.thumbnail" />
                  </figure>
                </div>
              </div>
              <div class="column is-6 pt-0">
                <div class="cup-content pl-5 pr-5 pb-5 is-flex is-flex-direction-column is-justify-content-center has-text-centered is-align-items-center">
                  <div class="content-text">
                    <p class="has-text-centered font-secondary font-18 pt-5">{{product.name}}</p>
                    <p class="has-text-centered font-18 fixed-height">{{this.getProductDescription()(product.id)}}</p>
                    <p class="has-text-centered font-18 price-text">{{ this.$t("message.pages.shopListPage.hinnatText") }}  {{maxDiscount(product)}} €/kpl</p>
                    <button class="button pm-button card-button" @click="goToDesignPage(product.id)">{{ this.$t("message.pages.shopListPage.buttonText") }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h3 class="has-text-centered font-secondary custom-black" id="lids">
          {{ this.$t("message.pages.shopListPage.lidsHeading") }}
        </h3>
        <div class="columns py-5 is-multiline bg-light">
          <div class="column is-6" v-for="(product, index) in $store.getters.getLids" :key="index">
            <div class="cup-card m-auto columns" v-if="product.resource_type == 'Lid'">
              <div class="column is-6 pb-0">
                <div class="cup-image is-align-items-center">
                  <figure class="image">
                    <img :src="product.thumbnail" />
                  </figure>
                </div>
              </div>
              <div class="column is-6 pt-0">
                <div class="cup-content pl-5 pr-5 pb-5 is-flex is-flex-direction-column is-justify-content-center has-text-centered is-align-items-center">
                  <div class="content-text">
                    <p class="has-text-centered font-secondary font-18 pt-5">{{product.name}}</p>
                    <p class="has-text-centered font-18 fixed-height">{{this.getProductDescription()(product.id)}}</p>
                    <p v-if="product.name == 'Kansi 80 mm'" class="has-text-centered font-18 price-text">{{ this.$t("message.pages.shopListPage.hinnatText") }}  0.06 €/kpl<br>{{ this.$t("message.pages.shopListPage.lidsAdditionalText") }}</p>
                    <p v-if="product.name == 'Kansi 90 mm'" class="has-text-centered font-18 price-text">{{ this.$t("message.pages.shopListPage.hinnatText") }}  0.07 €/kpl<br>{{ this.$t("message.pages.shopListPage.lidsAdditionalText") }}</p>
                    <button class="button pm-button card-button" @click="selectProductEditor(product.id, product.resource_type, 'cart')">{{ this.$t("message.pages.shopListPage.lidsButtonText") }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PmFooter />
  </section>
</template>

<script>
import PmNavbar from '../components/PmNavbar'
import PmFooter from '../components/PmFooter'
import {mapActions, mapGetters} from "vuex"
import addProductMixin from "@/mixins/addProduct"

export default {
  name: "ShopListPage",
  components: {
    PmNavbar,
    PmFooter
  },
  mounted() {
    let products = this.$store.getters.getProducts
    if (products.length == 0) {
      this.getProducts()
      this.listDiscountVolumes()
      this.listImageBank()
    }
    if (this.$route.hash) {
      this.$nextTick(() => {
        const element = document.querySelector(this.$route.hash)
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' })
        }
      })
    }
  },
  mixins: [addProductMixin],
  methods: {
    ...mapActions([
      'getProducts',
      'listDiscountVolumes',
      'listImageBank'
    ]),
    ...mapGetters([
      'getProductDescription'
    ]),
    maxDiscount(item) {
      let productPrices = this.$store.getters.getVolumesDiscount(item.id)
      let volume = productPrices[0].volume_point
      for (var idx = 0; idx < productPrices.length; idx++) {
        if (productPrices[idx].volume_point > volume ) {
          volume = productPrices[idx].volume_point
        }
      }
      let cupDiscount = productPrices.find(p => p.volume_point == volume)
      if (cupDiscount == undefined) {
        return 1
      }
      return cupDiscount.price
    },
    goToDesignPage(productId) {
      this.$router.push({
        name: 'DesignMethodPage',
        params: {
          productId: productId
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
@use '../assets/sass/variables' as *;

.shop-list-page {

  .custom-black {
    color: $font-color-primary;
  }

  .bg-white {
    background-color: #FFFFFF;
  }

  .bg-light {
    background-color: $pm-light;
  }

  .cup-content {
    height: 100%;
    .content-text {
      @media (min-width: 768px) {
        height: 100%;
        .fixed-height {
          height: 75px;
        }
        .price-text {
          margin-top: 50%;
        }
        button {
          margin-top: 10%;
        }
      }
    }
  }

  .cup-card {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 2px #00000034;
    .card-button {
      max-width: 181px;
      max-height: 45px;
    }
  }
}
</style>
