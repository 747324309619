<template>
  <section class="hero is-fullheight editor-page">
    <PmNavbar />
    <div class="hero-body light-bg">
      <div class="container">
        <div class="columns is-multiline p-5 white-bg">
          <div class="column is-12">
            <h3 class="has-text-centered cstm-font-color cstm-underline font-secondary">{{ this.$t("message.pages.editorPage.title") }}</h3>
          </div>
          <div class="column is-12">
            <p class="has-text-centered font-18 cstm-font-color">
              {{ this.$t("message.pages.editorPage.subtitle") }}
            </p>
          </div>
        </div>

        <div class="columns is-multiline mt-5 white-bg">
          <div class="column is-12">
            <p class="has-text-centered font-18 font-secondary py-5" v-if="product">
              {{product.name}}
            </p>
            <FabricComponent ref="fabriceditor" :product="product" v-if="product"></FabricComponent>
          </div>
          <div class="column is-12">
            <div class="editor-wrapper white-bg m-auto">
              <div
                class="editor-buttons-wrapper has-text-centered py-5 is-align-items-center"
              >
                <div class="columns is-multiline">
                  <div class="column is-4 is-offset-4 is-align-items-center is-justify-content-end is-relative">
                    <div class="text-wrapper is-inline-block">
                      <productAmount
                        :is-editable="true"
                        :item="selectedProduct"
                        v-model="selectedProduct.amount"
                        :product="selectedProduct.product"
                        class="is-size-5"
                      >
                      </productAmount>
                    </div>
                  </div>
                </div>
                <div class="columns is-multiline">
                  <div class="column is-4 is-offset-4 is-align-items-center is-justify-content-end is-relative">
                    <div class="column is-12 has-text-centered is-justify-content-center is-align-items-center">
                      <p class="font-18">{{ this.$t("message.pages.editorPage.costPrepText") }} {{ getVolumeDiscount(selectedProduct).price }} € = {{ Math.ceil(getItemLineTotalPrice(selectedProduct)).toFixed(2) }} € {{ this.$t("message.pages.editorPage.taxText") }} 0%</p>
                    </div>
                  </div>
                </div>
                <div class="columns is-multiline">
                  <div class="column is-4 is-offset-4 pb-0">
                    <button class="button pm-small-button mt-5" @click="goToCart()">{{ this.$t("message.pages.editorPage.goCartButtonText") }}</button>
                  </div>
                  <div class="column is-4 is-offset-4 pt-0">
                    <button class="button pm-small-button my-5" @click="addProduct()">{{ this.$t("message.pages.editorPage.addProductButtonText") }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PmFooter />
  </section>
</template>

<script>
import PmNavbar from '../components/PmNavbar'
import PmFooter from '../components/PmFooter'
import productAmount from "@/components/productAmount"
import {mapActions, mapMutations} from "vuex"
import addProductMixin from "@/mixins/addProduct"
import FabricComponent from "@/components/FabricComponent"
import cartMixin from "@/mixins/cartMixin"

export default {
  name: "EditorPage",
  components: {
    PmNavbar,
    PmFooter,
    FabricComponent,
    productAmount
  },
  data() {
    return {
      product: null
    }
  },
  mounted() {
    this.product = this.$store.getters.getProductById(this.$route.params.product)[0]
  },
  mixins: [addProductMixin, cartMixin],
  methods: {
    ...mapActions([
      'updateItem',
      'getOrder'
    ]),
    ...mapMutations([
      'setSpinnerState'
    ]),
    openTab(evt, tabName) {
      var i, x, tablinks;
      x = document.getElementsByClassName("content-tab")
      for (i = 0; i < x.length; i++) {
        x[i].style.display = "none"
      }
      tablinks = document.getElementsByClassName("tab")
      for (i = 0; i < x.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" is-active", "")
      }
      document.getElementById(tabName).style.display = "flex"
      evt.currentTarget.className += " is-active"
    },
    addProduct() {
      this.setSpinnerState(true)
      this.$refs.fabriceditor.removeGrid()
      let itemId = this.$route.params.item
      this.$refs.fabriceditor.getDataUrl()
      let texture = this.$refs.fabriceditor.texture[0].dataUrl
      this.updateItem(
          {
            'id': itemId,
            'label_png': texture,
            'amount': this.selectedProduct.amount,
            'fabric_metadata': this.selectedProduct.fabric_metadata,
            'draft': false
          }
      ).then(() => {
        this.getOrder()
          .then(() => {
            this.selectProductEditor(this.product.id, this.product.resource_type, 'editor')
          })
      })
    },
    goToCart() {
      this.setSpinnerState(true)
      this.$refs.fabriceditor.removeGrid()
      let uuid = this.$route.params.uuid
      let itemId = this.$route.params.item
      this.$refs.fabriceditor.getDataUrl()
      let texture = this.$refs.fabriceditor.texture[0].dataUrl
      this.updateItem(
          {
            'id': itemId,
            'label_png': texture,
            'amount': this.selectedProduct.amount,
            'fabric_metadata': this.selectedProduct.fabric_metadata,
            'draft': false
          }
      ).then(() => {
        this.getOrder()
          .then(() => {
            this.setSpinnerState(false)
            this.$router.push({
              name: 'Cart',
              params: {
                uuid: uuid
              }
            })
          })
      })
    }
  },
}
</script>

<style scoped lang="scss">
@use '../assets/sass/variables' as *;
.editor-page {
  .cstm-font-color {
    color: $font-color-primary;
  }

  .white-bg {
    background-color: #ffffff;
  }
  .light-bg {
    background-color: #f0efe4;
  }

  .editor-buttons-wrapper {
    margin: auto;
  }

  .pm-button {
    width: 181px;
    height: 45px;
    font-family: $font-secondary;
    font-size: 18px;
    justify-content: center;
  }

  .svg-inline--fa {
    cursor: pointer;
  }

  .pm-small-button {
    width: 100% !important;
    @media (max-width: 768px) {
      width: 80% !important;
    }
  }
}
</style>
