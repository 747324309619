<template>
  <section class="hero is-fullheight aboutus-page">
    <PmNavbar />
    <div class="hero-body">
      <div class="container">
        <div class="columns is-multiline">
          <div class="column is-12 p-0 mb-5">
            <figure class="image">
              <img src="../assets/img/Intersection1@2x.png" />
            </figure>            
          </div>
          <div class="column is-12 blue-bg py-5 mobile-content">
            <h2 class="font-white has-text-centered py-5">{{ this.$t("message.pages.aboutPage.title1") }}</h2>
            <p class="font-white has-text-centered pb-5">{{ this.$t("message.pages.aboutPage.p1") }}</p>
            <p class="font-white has-text-centered pb-5">{{ this.$t("message.pages.aboutPage.p2") }}</p>
            <p class="font-white has-text-centered pb-5">{{ this.$t("message.pages.aboutPage.p3") }}</p>
          </div>
          <div class="column is-12 light-bg has-text-centered py-5 mt-5 mobile-content" id="feedback-anchor">
            <div>
              <img class="cstm-icon" src="../assets/img/Icon-awesome-quote-right.svg" />
            </div>
            <h2 class="has-text-centered font-secondary my-4">{{ this.$t("message.pages.aboutPage.title2") }}</h2>
            <p class="has-text-centered pt-5">
              <i>”{{ this.$t("message.pages.aboutPage.italic1") }}”</i>
            </p>
            <p class="pt-2">
              <strong>{{ this.$t("message.pages.aboutPage.tiina") }}</strong>
            </p>

            <p class="has-text-centered mt-6">
              <i>”{{ this.$t("message.pages.aboutPage.italic2") }}”</i>
            </p>
            <p class="pt-2">
              <strong>{{ this.$t("message.pages.aboutPage.heini") }}</strong>
            </p>

            <p class="has-text-centered mt-6">
              <i>{{ this.$t("message.pages.aboutPage.italic3_p1") }}</i>
              <br/>
              <i>{{ this.$t("message.pages.aboutPage.italic3_p2") }}</i>
              <br/>
              <i>{{ this.$t("message.pages.aboutPage.italic3_p3") }}</i>
            </p>
            <p class="pt-2">
              <strong>{{ this.$t("message.pages.aboutPage.sakari") }}</strong>
            </p>
          </div>
          <div class="column is-12 has-text-centered py-5" id="video-anchor">
            <h2 class="secondary-font py-5">{{ this.$t("message.pages.aboutPage.title3") }}</h2>
            <video controls>
              <source src="../assets/video/pmvideo.mp4" type="video/mp4">
            Your browser does not support the video tag.
            </video>
          </div>

          <div class="column is-12">
            <div class="columns mobile-content">
              <div class="column is-6 contact-info">
                <h3 class="has-text-left pb-5">
                  {{ this.$t("message.pages.aboutPage.title4") }}
                </h3>
                <p class="has-text-left">
                  Mauri Reinilä
                </p>
                <p class="has-text-left">
                  {{ this.$t("message.pages.aboutPage.mauri1") }}
                </p>
                <p class="has-text-left">
                  +358 40 545 3497
                </p>
                <a class="has-text-left pb-5" href="mailto:mauri.reinila@packagemedia.fi">
                  mauri.reinila@packagemedia.fi
                </a>
                <p class="has-text-left pb-5">
                  {{ this.$t("message.pages.aboutPage.mauri2") }}
                </p>
                <p class="has-text-left pb-15">
                Hannu Rautakorpi
                </p>
                <p class="has-text-left">
                {{ this.$t("message.pages.aboutPage.hannu1") }}
                </p>
                <p class="has-text-left">
                +358 50 532 0575
                </p>
                <a class="has-text-left pb-5" href="mailto:hannu.rautakorpi@packagemedia.fi">
                hannu.rautakorpi@packagemedia.fi
                </a>
                <p class="has-text-left">
                {{ this.$t("message.pages.aboutPage.hannu2") }}
                </p>
              </div>
              <div class="column is-6">
                <figure class="image">
                  <img src="../assets/img/Group112@2x.png" />
                </figure>  
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PmFooter />
  </section>  
</template>

<script>
import PmNavbar from "../components/PmNavbar";
import PmFooter from "../components/PmFooter";

export default {
  name: "AboutUsPage",
  components: {
    PmNavbar,
    PmFooter,
  },
  data() {
    return {}
  },
  methods: {},
  mounted() {
    setTimeout(() => {
      if(this.$route.hash && this.$route.hash == '#video-anchor') {
        document.getElementById("video-anchor").scrollIntoView();
      }
    }, 500)
  },
  computed: {},
}
</script>

<style scoped lang="scss">
@use '../assets/sass/variables' as *;
.aboutus-page {
  .blue-bg {
    background-color: $pm-blue;
  }
  .cstm-icon {
    height: 80px;
    margin-top: 45px;
  }
  .light-bg {
    background-color: $pm-light;
  }
  .contact-info {
    p {
      font-size: 16px;
    }
  }
}
.mobile-content {
  @media (max-width: 768px) {
    padding: 20px;
  }
}
</style>
