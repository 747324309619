<template>
  <router-view :key="$route.fullPath"/>
  <SpinnerModal :spinning="spinnerActive"></SpinnerModal>
  <notificationModal></notificationModal>
  <CookieNotification v-if="cookieNotificationShow"/>
</template>

<script>
import {mapState} from "vuex"
import SpinnerModal from "@/modals/SpinnerModal"
import notificationModal from "@/modals/notificationModal"
import CookieNotification from "@/modals/CookieNotification"

export default {
  name: 'App',
  components: {
    SpinnerModal,
    notificationModal,
    CookieNotification
  },
  computed: {
    ...mapState([
      'spinnerActive',
      'cookieNotificationShow'
    ])
  }
}
</script>

<style lang="scss">
  @use '@/assets/sass/mybulma.scss';
</style>
