<template>
  
  <section class="hero is-fullheight landing-wrapper">
    <PmNavbar />
    <OrderResetModal ref="orderResetModal"></OrderResetModal>
    <div class="hero-body">
      <div class="container">
        
        <div class="columns is-flex-direction-column py-5 bg-image-blend has-text-centered">
          <div class="column is-12 pt-5 top-landing-page-content blue-bg-transparent">
            <h1 class="has-text-centered font-yellow"></h1>

            <h2 class="has-text-centered font-white">
              {{ this.$t("message.pages.landing.blueBoxText") }}
            </h2>
            
            <div class="is-flex is-justify-content-center pb-5 mt-3">
              <a @click="scrollMeTo('kuppiAnchor')">
                <i class="fa-solid fa-chevron-down fa-2xl is-clickable cstm-chevron" style="color: #ffffff;" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>

        <div class="columns is-multiline" style="margin-top: 15px;">
          <div class="column is-6 p-0">
            <figure class="image">
              <img src="../assets/img/kaikki_Y4A6309@2x.png" />
            </figure>
          </div>
          <div class="column is-6 light-bg py-5 is-flex is-flex-direction-column is-justify-content-space-around is-align-items-center">
            <p ref="kuppiAnchor" class="has-text-centered font-secondary font-25 cstm-underline">{{ this.$t("message.pages.landing.kuppiHeading") }}</p>
            <div class="card-body-text">
              <p class="has-text-centered font-18 mt-3 kuppi-text">{{ this.$t("message.pages.landing.kuppiText") }}</p>
              <p class="has-text-centered font-18 mt-3 kuppi-text">{{ this.$t("message.pages.landing.kuppiTextTwo") }}</p>
            </div>
            <button class="button pm-button mt-3" @click="$router.push({name: 'ShopList'})">{{ this.$t("message.pages.landing.kuppiButtonText") }}</button>
          </div>
        </div>
        <div class="columns is-multiline" style="margin-top: 15px;">
          <div class="column is-6 p-0">
            <figure class="image has-text-centered">
              <img src="../assets/img/kannet-kupit_muok.png" style="max-height: 335px; width: auto; margin-left: auto; margin-right: auto"/>
            </figure>
          </div>
          <div class="column is-6 light-bg py-5 is-flex is-flex-direction-column is-justify-content-space-around is-align-items-center">
            <p ref="kuppiAnchor" class="has-text-centered font-secondary font-25 cstm-underline">{{ this.$t("message.pages.landing.lidsHeading") }}</p>
            <div class="card-body-text">
              <p class="has-text-centered font-18 mt-3 kuppi-text">{{ this.$t("message.pages.landing.lidsText") }}</p>
            </div>
            <button class="button pm-button mt-3" @click="$router.push({name: 'ShopList', hash: '#lids'})">{{ this.$t("message.pages.landing.lidsButtonText") }}</button>
          </div>
        </div>
        <div class="columns is-multiline" style="margin-top: 15px;">
          <div class="column is-6 light-bg py-5 is-flex is-flex-direction-column is-justify-content-space-around is-align-items-center">
            <p class="has-text-centered font-secondary font-25 cstm-underline mb-3">{{ this.$t("message.pages.landing.tuotteetHeading") }}</p>
            <div class="card-body-text">
              <p class="has-text-centered font-18">{{ this.$t("message.pages.landing.tuotteetLineOne") }}</p>
              <p class="has-text-centered font-18">{{ this.$t("message.pages.landing.tuotteetLineTwo") }}</p>
              <p class="has-text-centered font-18">{{ this.$t("message.pages.landing.tuotteetLineThree") }}</p>
              <p class="has-text-centered font-18">{{ this.$t("message.pages.landing.tuotteetLineFour") }}</p>
            </div>
            <button class="button pm-button mt-3" @click="$router.push({name: 'ShopList'})">{{ this.$t("message.pages.landing.tuotteetButtonText") }}</button>
          </div>
          <div class="column is-6 p-0">
            <figure class="image">
              <img src="../assets/img/Rectangle_15@2x.png" />
            </figure>            
          </div>
        </div>
        <div class="columns light-bg" style="margin-top: 15px;">
          <div class="column is-4 is-flex is-flex-direction-column is-justify-content-start">
            <div class="img-container is-flex is-justify-content-center is-align-items-center">
              <img class="cstm-icon" src="../assets/img/Icon-awesome-quote-right.svg" />
            </div>
            <p class="has-text-centered font-18"><i>”{{ this.$t("message.pages.landing.italic1") }}”</i></p>
            <p class="has-text-centered font-18 has-text-weight-bold"><strong>- Heini</strong></p>
            <p class="has-text-centered font-18 is-underlined mokoko-medium"><a href="/about-us#feedback-anchor">{{ this.$t("message.pages.landing.italic1_1") }}</a></p>
          </div>
          <div class="column is-4 is-flex is-flex-direction-column is-justify-content-end">
            <div class="img-container is-flex is-justify-content-center is-align-items-center">
              <img class="cstm-icon" src="../assets/img/Icon-awesome-photo-video.svg" />
            </div>
            <p class="has-text-centered font-18">{{ this.$t("message.pages.landing.italic2") }}</p>
            <p class="has-text-centered font-18 is-underlined mokoko-medium"><a href="/about-us#video-anchor">{{ this.$t("message.pages.landing.italic2_2") }}</a></p>
          </div>
          <div class="column is-4 is-flex is-flex-direction-column is-justify-content-start">
            <div class="img-container is-flex is-justify-content-center is-align-items-center">
              <img class="cstm-icon" src="../assets/img/Icon-awesome-lightbulb.svg" />
            </div>
            <p class="has-text-centered font-18">{{ this.$t("message.pages.landing.italic3") }}</p>
            <p class="has-text-centered font-18 is-underlined mokoko-medium"><a href="/tips">{{ this.$t("message.pages.landing.italic3_1") }}</a></p>
          </div>                    
        </div>
        <div class="columns light-bg pb-5" style="margin-top: 15px;">
          <div class="column is-offset-1 is-10 has-text-centered py-5">
            <h2 class="has-text-centered">
               {{ this.$t("message.pages.landing.otsikkoTitle") }}
            </h2>
            <p class="title has-text-centered font-18 font-secondary mt-5 pt-5 mb-1">{{ this.$t("message.pages.landing.otsikkoHeadingOne") }}</p>
            <p class="body-text has-text-centered font-18 pb-5 mb-5">{{ this.$t("message.pages.landing.otsikkoTextOne") }}</p>
            <p class="title has-text-centered font-18 font-secondary mb-1">{{ this.$t("message.pages.landing.otsikkoHeadingTwo") }}</p>
            <p class="body-text has-text-centered font-18 pb-5 mb-5">{{ this.$t("message.pages.landing.otsikkoTextTwo") }}</p>
            <p class="title has-text-centered font-18 font-secondary mb-1">{{ this.$t("message.pages.landing.otsikkoHeadingThree") }}</p>
            <p class="body-text has-text-centered font-18 pb-5 mb-5">{{ this.$t("message.pages.landing.otsikkoTextThree") }}</p>
          </div>
        </div>         
        <div class="columns light-bg" style="margin-top: 15px;">
          <div class="column is-4 is-flex is-flex-direction-column is-justify-content-start">
            <div class="img-container is-flex is-justify-content-center is-align-items-center">
              <img class="cstm-icon" src="../assets/img/recycle-solid.svg" />
            </div>
            <p class="has-text-centered font-18 pb-4">
              {{ this.$t("message.pages.landing.imageOneText") }}
            </p>
          </div>
          <div class="column is-4 is-flex is-flex-direction-column is-justify-content-end">
            <div class="img-container is-flex is-justify-content-center is-align-items-center">
              <img class="cstm-icon" src="../assets/img/map-marker-alt-solid.svg" />
            </div>
            <p class="has-text-centered font-18 pb-4">
              {{ this.$t("message.pages.landing.imageTwoText") }}
            </p>
          </div>
          <div class="column is-4 is-flex is-flex-direction-column is-justify-content-start">
            <div class="img-container is-flex is-justify-content-center is-align-items-center">
              <img class="cstm-icon" src="../assets/img/balance-scale-solid.svg" />
            </div>
            <p class="has-text-centered font-18 pb-4">
              {{ this.$t("message.pages.landing.imageThreeText") }}
            </p>
          </div>                    
        </div>
        <div class="columns light-bg newsletter-section" style="margin-top: 15px;">
          <div class="column is-offset-1 is-10 has-text-centered py-5">
            <p class="title has-text-centered font-secondary">
               {{ this.$t("message.pages.landing.tilaaText") }}
            </p>
            <p class="has-text-centered mt-5 pt-1 mb-5 is-white">
              {{ this.$t("message.pages.landing.pysyText") }}
            </p>
            <div class="newsletter-inputs">
              <input
                   class="input pm-input"
                   type="text"
                   placeholder="Sähköpostiosoite"
                   v-model=email
               >
              <button class="button pm-button main-button" @click="postNewsletter">
                {{ this.$t("message.pages.landing.tilaaButton") }}
              </button>             
            </div>
            <p class="mb-5 is-danger font-secondary email-error is-block" v-if="emailMessageError">
              {{ this.$t("message.pages.landing.emailErrorText") }}
            </p>             
          </div>
        </div>        
        <div class="columns is-multiline" style="margin-top: 15px;">
          <div class="column is-6 p-0">
            <figure class="image">
              <img src="../assets/img/doris_vaaka@2x.png" />
            </figure>             
          </div>
          <div class="column is-6 light-bg py-5 is-flex is-flex-direction-column is-justify-content-space-around is-align-items-center">
            <p class="has-text-centered font-secondary font-25 cstm-underline">{{ this.$t("message.pages.landing.kuppiOmallaHeading") }}</p>
            <div class="card-body-text">
              <p class="has-text-centered font-18 mt-3 kuppi-text">{{ this.$t("message.pages.landing.kuppiOmallaText") }}</p>
              <p class="has-text-centered font-18 mt-5 kuppi-text">{{ this.$t("message.pages.landing.kuppiOmallaLastLineText") }}</p>
            </div>
            <a class="button pm-button mt-3" href="https://pyrollkauppa.fi/fi" target="_blank">{{ this.$t("message.pages.landing.kuppiOmallaButtonText") }}</a>
          </div>          
        </div>               
      </div>
    </div>
    <NewsletterThanksModal ref="newsletterThanksModal" />
    <PmFooter />
  </section>
</template>

<script>
import PmNavbar from '../components/PmNavbar'
import PmFooter from '../components/PmFooter'
import NewsletterThanksModal from '../modals/NewsletterThanksModal'
import {mapActions, mapGetters, mapMutations} from 'vuex'
import { useState } from "vue-gtag-next"
import OrderResetModal from "@/modals/OrderResetModal"

export default {
  name: "LandingPage",
  components: {
    PmNavbar,
    PmFooter,
    NewsletterThanksModal,
    OrderResetModal
  },
  data() {
    return {
      email: '',
      emailMessageError: false
    };
  },
  methods: {
    ...mapActions([
      'getProducts',
      'listDiscountVolumes',
      'listImageBank',
      'getServiceFees'
    ]),
    ...mapMutations([
      'resetStore',
      'setOrder',
      'setReseting',
      'setSpinnerState',
      'setCartSpinnerState',
      'setResetOrderNotificationShow'
    ]),
    postNewsletter() {
      // check valid email
      let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

      if(this.email && this.email.match(validRegex)) {
        this.emailMessageError = false
        this.$store.dispatch('postNewsletter', {'email': this.email}).then(() => {
          // open newletters thanks modal
          this.$refs.newsletterThanksModal.showModal()
          this.email = ''
          window.scrollTo(0, 0);
        })        
      } else {
        this.emailMessageError = true
      }
    },
    scrollMeTo(ref) {
      let element = this.$refs[ref]
      let top = element.offsetTop
      window.scrollTo(0, top)
    }
  },
  mounted() {
    if (this.$store.getters.getResetOrderNotificationShow) {
      this.$refs.orderResetModal.showModal()
      this.setResetOrderNotificationShow(false)
    }
    if (this.$store.getters.getReseting) {
      this.setReseting(false)
      this.resetStore()
    }
    this.getProducts()
    this.listDiscountVolumes()
    this.listImageBank()
    this.getServiceFees()
    this.setSpinnerState(false)
    this.setCartSpinnerState(false)
    // if user exists and enters app again we apply analytics again if enabled
    if (this.$store.getters.getGoogleCookieEnabled) {
      const { property } = useState();
      property.value = {
        id: process.env.VUE_APP_GA_ID
      }
    }

  },
};
</script>

<style scoped lang="scss">
@use '../assets/sass/variables' as *;
.landing-wrapper {

  .cstm-chevron {

  }

  .main-button {
    width: 450px;
    @media (max-width: 768px) {
      transform: scale(0.6);
    }
  }

  .bg-image-blend {
    mix-blend-mode: multiply;
    background-image: url("../assets/img/cups_Kouvola_and_Kotka-9@2x.png");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: 100px;
    padding-bottom: 0 !important;
    @media (max-width: 768px) {
      background-position-y: 180px;
    }
    .top-landing-page-content {
      margin-top: 300px;
      padding: 0px;
      h1 {
        font-size: 45px;
        font-weight: bold;
      }
      h2 {
        font-size: 35px;
      }
      @media (max-width: 768px) {
        h1 {
          font-size: 25px;
        }
        h2 {
          font-size: 20px;
        }
      }
      @media (max-width: 768px) {
        padding: 20px;
        margin-top: 130px;
      }
    }
  }

  .img-container {
    height: 150px;
    flex-grow: 1;
  }
  .cstm-icon {
    height: 80px;
  }

  .kuppi-text {
    max-width: 500px;
  }
  .blue-bg {
    background-color: $pm-light;
  }
  .blue-bg-transparent {
    background-color: rgba(50, 168, 183, 0.8)
  }
  .light-bg {
    background-color: $pm-light;
  }
  .p-100 {
    padding-top: 100px;
  }
  .email-error {
    color: red;
    font-size: 18px;
    margin-top: 10px;
  }
}

.card-body-text {
  @media (max-width: 768px) {
    padding: 20px;
  }
}

.body-text {
  @media (max-width: 768px) {
    padding: 20px;
  }
}

.newsletter-section {
  background-color: $pm-blue !important;
  p.title {
    font-size: 25px;
    color: white;
  }
  p {
    font-size: 20px;
    color: white;
  }
  .newsletter-inputs {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    input {
      width: 440px;
      margin-right: 60px;
    }
    button.button {
      width: 220px;
    }
  }
}
</style>
