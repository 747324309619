<template>
  <teleport to="#modals">
    <div class="modal is-active is-layout-fixed" v-if="active" tabindex="0" ref="modalroot">
      <div class="modal-background" @click="closeModal()"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <div class="buttons is-right">
            <button class="button is-secondary is-inverted" @click="closeModal()">
              <span class="icon is-medium">
                <i class="fas fa-2x fa-window-close"></i>
              </span>
            </button>
          </div>
        </header>
        <section class="modal-card-body">
        <div class="columns is-multiline">
          <div class="column is-6 is-offset-3 has-text-centered bg-white">
            <h2 class="mb-5 font-secondary font-20">{{ this.$t("message.modals.editorHelpModal.h2_1") }}</h2>

            <p class="font-18 font-secondary has-text-centered">{{ this.$t("message.modals.editorHelpModal.p_1") }}</p>
            <a class="font-secondary font-18 is-underlined font-blue" @click="$router.push({name: 'FaqPage'})">{{ this.$t("message.modals.editorHelpModal.a_1") }}</a>
            <a class="font-secondary font-18 is-underlined is-block font-blue" @click="$router.push({name: 'TipsPage'})">{{ this.$t("message.modals.editorHelpModal.a_2") }}</a>

            <div class="cstm-b-border m-auto py-3"></div>
          </div>

          <div class="column is-6 is-offset-3 has-text-centered bg-white">
            <p class="font-18 font-secondary has-text-centered">{{ this.$t("message.modals.editorHelpModal.p_2") }}</p>
            <p class="font-18 has-text-centered">{{ this.$t("message.modals.editorHelpModal.p_3") }}</p>
            <button
                class="button pm-button mt-4 font-secondary"
                @click="$router.push({name: 'ShopList'})"
              >
                <span>{{ this.$t("message.modals.editorHelpModal.span_1") }}</span>
            </button>

            <div class="cstm-b-border m-auto py-3"></div>
          </div>

          <div class="column is-6 is-offset-3 bg-white">
            <p class="font-18 font-secondary has-text-centered">{{ this.$t("message.modals.editorHelpModal.p_4") }}</p>
            <p class="font-blue is-underlined has-text-centered font-blue font-secondary">pmpro@packagemedia.fi</p>
            <p class="font-blue has-text-centered font-blue font-secondary">puh. 050 5320575</p>
          </div>

        </div>
        </section>
      </div>
    </div>
  </teleport>
</template>

<script>

export default {
  name: "EditorHelpModal",
  components: {
  },
  data() {
    return {
      active: false,
    };
  },
  methods: {
    closeModal() {
      this.active = false;
    },
    showModal() {
      this.active = true;
    },
  },
};
</script>

<style scoped lang="scss">
@use '../assets/sass/variables' as *;
.modal {
  .modal-content {
    background-color: $pm-light !important;
  }
  .close-button {
    cursor: pointer;
  }
  .bg-white {
    background-color: #FFFFFF;
  }
  .cstm-b-border {
    width: 50%;
    height: 1px;
    border-bottom: 1px solid #000000;
  }
}
.button.is-secondary.is-inverted:hover {
    background-color: initial;
}

.is-layout-fixed {
    position: fixed !important;
}

.modal {
  z-index: 100;
  .buttons {
    width: 100%;
  }
  .modal-card {
    width: 50%;
    @media (max-width: 768px) {
      min-width: 0px !important;
      width: 90% !important;
    }
  }
}
.modal-card-body {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  p {
    font-size: 20px;
  }
  @media (max-width: 768px) {
    padding: 20px;
  }
}
.modal-card-head {
  padding: 0px;
  .button {
    color: #D9C731;
  }
}
</style>
