<template>
  <div class="colorPickerExtraWrapper has-text-centered">
    <p class="has-text-centered label-bar-color">{{ this.$t("message.components.colorPickerFooter.p1") }}</p>
    <div class="colorPickerBar" :style="{backgroundColor: bgColor || '#44BDC7'}"></div>

    <a
        class="my-3 is-flex is-flex-direction-column is-align-items-center editor-text-icon"
        @click="confirmButton()"
    >
      <span class="editor-action-text">{{ this.$t("message.components.fabricComponent.selectColor") }}</span>
    </a>

    <a
        class="is-flex is-flex-direction-column is-align-items-center editor-text-icon"
        @click="cancelButton()"
    >
      <span class="editor-action-text">{{ this.$t("message.components.fabricComponent.cancelColor") }}</span>
    </a>

  </div>
</template>

<script>

export default {
  name: "ColorPickerFooter",
  props: ['bgColor',],
  data(){
    return {
      
    }
  },
  methods: {
    confirmButton() {
      this.$emit('cp-confirmButton')
    },
    cancelButton() {
      this.$emit('cp-cancelButton')
    }
  }
}
</script>

<style scoped lang="scss">
@use '../assets/sass/variables' as *;

.colorPickerExtraWrapper {
  padding: 6px;
}

.colorPickerBar {
  height: 25px;
  width: 100%;
  border: 1px solid black;
}

.editor-action-text {
  color: $pm-blue;
  font-size: 15px;
  font-family: "Open Sans";
}

.label-bar-color {
  font-family: "Open Sans";
  font-size: 12.8px;
  color: $pm-blue;
}

</style>
